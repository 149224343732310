import React from "react";
import { ReactComponent as ENABLED } from "../img/Status-ENABLED.svg";
import { ReactComponent as EDITED } from "../img/Status-EDITED.svg";
import { ReactComponent as DISABLED } from "../img/Status-DISABLED.svg";

export default function StatusLabel(props) {
  if (props.status === "ENABLED") {
    return <ENABLED />;
  } else if (props.status === "EDITED") {
    return <EDITED />;
  } else {
    return <DISABLED />;
  }
}
