import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CustomDialog from "../components/CustomDialog";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  main: {
    width: "562px",
    height: "308px",
    display: "flex",
    flexDirection: "column",
    "& *": {
      fontFamily: "IDEMIA"
    }
  },
  top: {
    flexGrow: "1",
    flexShrink: "1"
  },
  bottom: {
    width: "562px",
    height: "190px",
    background: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: "0",
    flexShrink: "0"
  },
  buttons: {
    display: "flex"
  }
}));

export default function DisableAllServiceDialog(props) {
  const classes = useStyles();

  return (
    <CustomDialog
      open={props.open}
      showWarning={props.open}
      header={
        <Typography variant="h3">
          {"Are you sure you want to disable all services?"}
        </Typography>
      }
      content={
        <Typography variant="body1">
          If you disable this program, all changes that you have made will be
          lost.
        </Typography>
      }
      showCloseX={true}
      closeAction={props.closeHandler}
      leftButtonText={"Cancel"}
      onLeftButtonClick={props.closeHandler}
      rightButtonText={"Disable"}
      onRightButtonClick={props.disableHandler}
      id="closureModal"
    />
  );
}
