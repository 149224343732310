import { React } from "react";
import CustomDialog from "./CustomDialog";

export default function GoogleMapDialog(props) {
  return (
    <CustomDialog
      open={props.showMap}
      header={"Map of Location"}
      showCloseX={true}
      closeAction={() => props.setShowMap(false)}
      content={
        <iframe
          title={"Location Map"}
          style={{
            width: "600px",
            height: "450px",
            border: 0,
          }}
          id="viewMapIframe"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDJ5S8xj30pChADEvmps9UJaIYRajdgb8s&q=${encodeURIComponent(
            props.address
          )}`}
        />
      }
    />
  );
}
