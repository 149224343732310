import { messages } from "../Constants/Message";

export function getLatAndLong(street, city, state) {
  const url = new URL("https://us-street.api.smartystreets.com/street-address");
  url.searchParams.append("street", street);
  url.searchParams.append("city", city);
  url.searchParams.append("state", state);
  url.searchParams.append("key", "7503870828448785");

  // [{"input_index":0,"candidate_index":0,"delivery_line_1":"1600 Amphitheatre Pkwy","last_line":"Mountain View CA 94043-1351","delivery_point_barcode":"940431351000","components":{"primary_number":"1600","street_name":"Amphitheatre","street_suffix":"Pkwy","city_name":"Mountain View","default_city_name":"Mountain View","state_abbreviation":"CA","zipcode":"94043","plus4_code":"1351","delivery_point":"00","delivery_point_check_digit":"0"},"metadata":{"record_type":"S","zip_type":"Standard","county_fips":"06085","county_name":"Santa Clara","carrier_route":"C909","congressional_district":"18","rdi":"Commercial","elot_sequence":"0103","elot_sort":"A","latitude":37.423570,"longitude":-122.086610,"precision":"Zip9","time_zone":"Pacific","utc_offset":-8,"dst":true},"analysis":{"dpv_match_code":"Y","dpv_footnotes":"AABB","dpv_cmra":"N","dpv_vacant":"N","dpv_no_stat":"Y","active":"Y"}}]
  return fetch(url.href, {}).then((response) => {
    if (response.ok) {
      return response.json().then((responseJson) => {
        const latitude = responseJson[0].metadata.latitude;
        const longitude = responseJson[0].metadata.longitude;
        return {
          latitude,
          longitude,
        };
      });
    } else {
      console.error("Smarty Streets error: " + response.statusText);
      throw response;
    }
  });

  // This can be used for local testing if smarty streets is not available
  // return Promise.resolve({latitude: 1, longitude: 2})
}

export function populateLatLong(locationObj, setLocationObj, setAlertMessage) {
  if (locationObj.addressLine1 && locationObj.city && locationObj.state) {
    getLatAndLong(
      locationObj.addressLine1,
      locationObj.city,
      locationObj.state.split(" -")[0]
    )
      .then((geoObject) => {
        setLocationObj({
          ...locationObj,
          latitude: geoObject.latitude,
          longitude: geoObject.longitude,
        });
      })
      .catch((error) => {
        setAlertMessage(messages.smartyStreetError);
      });
  }
}

export function getVerifyAddress(street, city, state, zipcode, street2) {
  const url = new URL("https://us-street.api.smartystreets.com/street-address");
  url.searchParams.append("street", street);
  url.searchParams.append("city", city);
  url.searchParams.append("state", state);
  url.searchParams.append("zipcode", zipcode);
  url.searchParams.append("street2", street2);
  url.searchParams.append("candidates", 3);
  url.searchParams.append("key", "7503870828448785");

  // This is for the cloud
  return fetch(url.href, {}).then((response) => {
    if (response.ok) {
      return response.json().then((responseJson) => {
        return responseJson;
      });
    } else {
      console.error("Smarty Streets error: " + response.statusText);
      throw response;
    }
  });

  // This is for local
  // return Promise.resolve([
  //   {
  //     input_index: 0,
  //     candidate_index: 0,
  //     delivery_line_1: "1109 S 9th Ave",
  //     last_line: "Phoenix AZ 85007-3646",
  //     delivery_point_barcode: "850073646993",
  //     components: {
  //       primary_number: "1109",
  //       street_predirection: "S",
  //       street_name: "9th",
  //       street_suffix: "Ave",
  //       city_name: "Phoenix",
  //       default_city_name: "Phoenix",
  //       state_abbreviation: "AZ",
  //       zipcode: "85007",
  //       plus4_code: "3646",
  //       delivery_point: "99",
  //       delivery_point_check_digit: "3",
  //     },
  //     metadata: {
  //       record_type: "H",
  //       zip_type: "Standard",
  //       county_fips: "04013",
  //       county_name: "Maricopa",
  //       carrier_route: "C019",
  //       congressional_district: "07",
  //       building_default_indicator: "Y",
  //       rdi: "Residential",
  //       elot_sequence: "0185",
  //       elot_sort: "A",
  //       latitude: 33.437382,
  //       longitude: -112.084404,
  //       coordinate_license: 1,
  //       precision: "Rooftop",
  //       time_zone: "Mountain",
  //       utc_offset: -7,
  //     },
  //     analysis: {
  //       dpv_match_code: "D",
  //       dpv_footnotes: "AAN1",
  //       dpv_cmra: "N",
  //       dpv_vacant: "N",
  //       dpv_no_stat: "Y",
  //       active: "Y",
  //       footnotes: "H#L#I#",
  //     },
  //   },
  //   {
  //     input_index: 0,
  //     candidate_index: 1,
  //     delivery_line_1: "1109 N 9th St",
  //     last_line: "Phoenix AZ 85006-2734",
  //     delivery_point_barcode: "850062734096",
  //     components: {
  //       primary_number: "1109",
  //       street_predirection: "N",
  //       street_name: "9th",
  //       street_suffix: "St",
  //       city_name: "Phoenix",
  //       default_city_name: "Phoenix",
  //       state_abbreviation: "AZ",
  //       zipcode: "85006",
  //       plus4_code: "2734",
  //       delivery_point: "09",
  //       delivery_point_check_digit: "6",
  //     },
  //     metadata: {
  //       record_type: "S",
  //       zip_type: "Standard",
  //       county_fips: "04013",
  //       county_name: "Maricopa",
  //       carrier_route: "C017",
  //       congressional_district: "07",
  //       rdi: "Residential",
  //       elot_sequence: "0308",
  //       elot_sort: "A",
  //       latitude: 33.460388,
  //       longitude: -112.061768,
  //       coordinate_license: 1,
  //       precision: "Rooftop",
  //       time_zone: "Mountain",
  //       utc_offset: -7,
  //     },
  //     analysis: {
  //       dpv_match_code: "Y",
  //       dpv_footnotes: "AABB",
  //       dpv_cmra: "N",
  //       dpv_vacant: "N",
  //       dpv_no_stat: "N",
  //       active: "Y",
  //       footnotes: "L#I#",
  //     },
  //   },
  // ]);
}

export function verifyAddress(location) {
  if (
    location.addressLine1 &&
    location.city &&
    location.state &&
    location.postalCode
  ) {
    return getVerifyAddress(
      location.addressLine1,
      location.city,
      location.state.split(" -")[0],
      location.postalCode,
      !location.addressLine2 ? "" : location.addressLine2
    )
      .then((geoObject) => {
        if (geoObject.length === 0) {
          // Unable to verify address - throw error
          throw Error("Cannot verify address");
        } else {
          return geoObject;
        }
      })
      .catch((e) => {
        throw Error("Cannot verify address");
      });
  }
}
