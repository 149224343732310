import React, { forwardRef, useImperativeHandle } from "react";
import moment, { now } from "moment";
import CustomDialog from "../components/CustomDialog";
import Typography from "@material-ui/core/Typography";

let logoutTimeout = [];
let popupTimeout = [];

const UserSessionTimeoutService = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const warningTime = 3 * 60 * 1000;
  let warnIn = 0;

  const [countDown, setCountDown] = React.useState(0);

  React.useEffect(() => {
    let timerId;

    if (countDown > 0) {
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [countDown]);

  const doCancel = () => {
    props.oktaAuth.signOut();
    clearTimeouts(logoutTimeout);
    logoutTimeout = [];
    clearTimeouts(popupTimeout);
    popupTimeout = [];
    setOpen(false);
  };

  const doStayLoggedIn = () => {
    props.oktaAuth.token.renewTokens();
    props.oktaAuth.session.refresh().then((x) => {
      startTimeoutSequence();
    });
    setCountDown(0);
    setOpen(false);
  };

  function clearTimeouts(timeoutArr) {
    for (const index in timeoutArr) {
      clearTimeout(timeoutArr[index]);
    }
  }

  function startPopupTimeout() {
    if (popupTimeout) {
      clearTimeouts(popupTimeout);
      popupTimeout = [];
    }

    if (logoutTimeout) {
      clearTimeouts(logoutTimeout);
      logoutTimeout = [];
    }

    clearTimeouts(popupTimeout);
    popupTimeout = [];
    popupTimeout.push(
      setTimeout(() => {
        props.oktaAuth.session.exists().then((sessionExists) => {
          if (sessionExists) {
            setOpen(true);
            setCountDown(120);
            clearTimeouts(logoutTimeout);
            logoutTimeout = [];
            logoutTimeout.push(
              setTimeout(() => {
                props.oktaAuth.signOut();
              }, 2 * 60 * 1000)
            );
          } else {
            clearTimeouts(popupTimeout);
            popupTimeout = [];
            props.oktaAuth.signOut();
          }
        });
      }, warnIn)
    );
  }

  /* This method is called when the okta session ends.*/
  function startTimeoutSequence() {
    return props.oktaAuth.session
      .exists()
      .then((sessionExists) => {
        if (sessionExists) {
          props.oktaAuth.session
            .get()
            .then((y) => {
              if (y && y.expiresAt) {
                const currentTime = now();
                warnIn =
                  moment(y.expiresAt).diff(
                    moment(currentTime),
                    "milliseconds"
                  ) - warningTime;
                startPopupTimeout();
              }
            })
            .catch((x) => console.error("Error getting okta session."));
        } else {
          if (window.location.pathname !== "/login") {
            props.oktaAuth.signOut();
          }
        }
      })
      .catch((x) => console.error("Error getting okta session exists."));
  }

  useImperativeHandle(ref, () => ({
    pageChanged(location) {
      startTimeoutSequence();
    },
  }));

  return (
    <CustomDialog
      open={open}
      showCloseX={false}
      closeAction={null}
      header={"Logout Warning"}
      content={
        <Typography variant="h3">
          Your session will expire in {countDown} seconds
        </Typography>
      }
      leftButtonText={"Cancel"}
      onLeftButtonClick={doCancel}
      rightButtonText={"Ok"}
      rightButtonColor={"Primary"}
      onRightButtonClick={doStayLoggedIn}
    />
  );
});

export default UserSessionTimeoutService;
