import { Button, Dialog, Typography } from "@material-ui/core";
import CloseX from "../svg/CloseX";
import AlertFailure from "../svg/AlertFailure";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { React } from "react";
import NavButtons from "./NavButtons";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  mainSection: {
    minWidth: "560px",
    minHeight: "300px",
    display: "flex",
    flexDirection: "column"
  },
  closeButton: {
    position: "absolute",
    right: "0",
    top: "8px"
  },
  closeButtonX: {
    fontSize: "20px"
  },
  headerSection: {
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  iconContainer: {
    fontSize: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10px"
  },
  headerText: {
    fontWeight: "600",
    fontSize: "20px",
    marginTop: "15px"
  },
  contentSection: {
    background: "#F2F2F2",
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    padding: "15px"
  },
  contentText: {},
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end"
  },
  leftButton: {},
  rightButton: {}
}));

export default function CustomDialog(props) {
  const classes = useStyles();

  return (
    <Dialog open={props.open}>
      <Box className={classes.mainSection} id={props.id}>
        <Box className={classes.iconContainer}>
          {props.showWarning ? <AlertFailure /> : null}
        </Box>
        {props.showCloseX ? (
          <Button className={classes.closeButton} onClick={props.closeAction}>
            <CloseX className={classes.closeButtonX} />
          </Button>
        ) : null}
        <Box className={classes.headerSection}>
          <Box className={classes.iconContainer}>{props.icon}</Box>
          <Typography className={classes.headerText}>{props.header}</Typography>
        </Box>
        <Box className={classes.contentSection}>
          {typeof props.content === "object" ? (
            props.content
          ) : (
            <Typography className={classes.contentText}>
              {props.content}
            </Typography>
          )}
          <Box className={classes.buttonContainer}>
            <NavButtons
              hideLeftButton={!props.leftButtonText}
              leftButtonText={props.leftButtonText}
              handleLeftButtonClick={props.onLeftButtonClick}
              leftButtonId={"customDialogLeftButton"}
              leftButtonDisabled={props.leftButtonDisabled}
              leftButtonColor={
                props.leftButtonColor ? props.leftButtonColor : "primary"
              }
              hideRightButton={!props.rightButtonText}
              rightButtonText={props.rightButtonText}
              handleRightButtonClick={props.onRightButtonClick}
              rightButtonId={"customDialogRightButton"}
              rightButtonDisabled={props.rightButtonDisabled}
              rightButtonColor={
                props.rightButtonColor ? props.rightButtonColor : "secondary"
              }
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
