import Button from "@material-ui/core/Button";
import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 0,
    padding: 0,
    flexGrow: 0,
    flexShrink: 0,
    height: "45px",
    minWidth: "47px",
    maxWidth: "47px",
  },
}));

export const DaysOfWeekButtons = (props) => {
  const classes = useStyles();

  return (
    <Box>
      {props.daysOfWeek.getDays().map((day, index) => (
        <Button
          color={"Primary"}
          name={day}
          onClick={() => props.dayButtonHandler(day)}
          variant={props.daysOfWeek[day] === false ? "outlined" : "contained"}
          className={`${classes.button} ${
            props.rowIndex !== undefined ? day + "-" + props.rowIndex : day
          }`}
          key={props.daysOfWeek.toKey(day, index)}
        >
          {day.slice(0, 1).toUpperCase()}
        </Button>
      ))}
    </Box>
  );
};
