import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { React } from "react";
import CustomTable from "../components/CustomTable";
import Box from "@material-ui/core/Box";

export default function ClosureVerifyAddress(props) {
  return (
    <RadioGroup
      value={props.selectedAddress}
      onChange={props.selectedAddressChanged}
    >
      <Box style={{ marginBottom: "15px" }}>
        <CustomTable
          headerVals={["Verified Addresses"]}
          rows={props.verifiedAddresses.map((address, index) => [
            <FormControlLabel
              value={`${index}`}
              control={<Radio id={`verifiedAddress-${index}`} />}
              label={`${address.delivery_line_1}, ${address.last_line}`}
            />,
          ])}
        />
      </Box>
      <Box style={{ marginBottom: "15px" }}>
        <CustomTable
          headerVals={["Entered Address (Unverified)"]}
          rows={[
            [
              <FormControlLabel
                value="3"
                control={<Radio id="unVerifiedAddress" />}
                label={`${props.enteredAddress.addressLine1}, ${props.enteredAddress.city} ${props.enteredAddress.state} ${props.enteredAddress.postalCode}`}
              />,
            ],
          ]}
        />
      </Box>
    </RadioGroup>
  );
}
