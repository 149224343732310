import { makeStyles } from "@material-ui/core/styles";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MagnifyingGlass from "../svg/MagnifyingGlass";
import { createRef, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { IconButton } from "@material-ui/core";
import CloseX from "../svg/CloseX";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
  },
  inputLabelNoShrink: {
    transform: "translate(32px, 14px) scale(1)",
  },
  listbox: {
    width: "492px",
    margin: 0,
    padding: "15px 0 0",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    maxHeight: 200,
    border: "none",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    fontSize: "12px",
    letterSpacing: "-0.15px",
    display: "flex",
    flexDirection: "column",
    color: "#676767",
    "& p": {
      margin: 0,
      padding: "0 22px 7px",
      flexGrow: 0,
      flexShrink: 0,
    },
    "& ul": {
      listStyle: "none",
      margin: 0,
      padding: 0,
      overflow: "auto",
      flexGrow: 1,
      flexShrink: 1,
    },
    "& li": {
      color: "black",
      fontSize: "14px",
      padding: "7px 22px",
    },
    '& li[data-focus="true"]': {
      backgroundColor: "#F2F2F2",
      cursor: "pointer",
    },
    "& li:active": {
      backgroundColor: "#E2E2E2",
    },
  },
}));

export default function Search(props) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [shrink, setShrink] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const textFieldRef = createRef();

  useEffect(() => {
    props.valueChanged(value);
    if (props.clearTextOnValueChanged) {
      setValue(null);
    }
  }, [value]);

  const inputChanged = (event, value) => {
    setValue(value);
  };

  const onOpen = () => {
    setShrink(true);
    setHasFocus(true);
  };

  const onClose = (event) => {
    if (!event.target.outerText && !value) {
      setShrink(false);
    }
    setHasFocus(false);
  };

  const keyUp = (event) => {
    if (event.key === "Enter") {
      const textFieldValue = textFieldRef.current
        .getElementsByClassName("MuiInputBase-root")[0]
        .getElementsByTagName("input")[0].value;
      if (props.searchOptions.includes(textFieldValue)) {
        setValue(textFieldValue);
      }
    }
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    options: props.searchOptions,
    getOptionLabel: (option) =>
      props.optionsValue ? option[props.optionsValue] : option,
    filterOptions: (options, filterOptionState) => {
      return options.filter((option) =>
        props.customFilter
          ? props.customFilter(option, filterOptionState.inputValue)
          : filterOptionState.inputValue
          ? filterOptionState
              .getOptionLabel(option)
              .toLowerCase()
              .indexOf(filterOptionState.inputValue.toLowerCase()) >= 0
          : true
      );
    },
    onChange: inputChanged,
    onOpen: onOpen,
    onClose: onClose,
    openOnFocus: true,
    value: value,
    autoHighlight: true,
    clearOnEscape: true,
  });

  return (
    <div style={props.style}>
      <div {...getRootProps()}>
        <TextField
          InputLabelProps={{
            ...getInputLabelProps(),
            shrink: shrink,
            className: shrink ? undefined : classes.inputLabelNoShrink,
            style: {
              fontFamily: "IDEMIA",
            },
          }}
          InputProps={{
            ...getInputProps(),
            style: {
              fontFamily: "IDEMIA",
              height: "37px",
              width: "492px",
              paddingLeft: "0",
              paddingRight: "0",
            },
            startAdornment: (
              <InputAdornment position={"start"}>
                <Box
                  style={{
                    fontSize: "20px",
                    height: "37px",
                    width: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MagnifyingGlass />
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton
                  style={{
                    fontSize: "20px",
                    height: "37px",
                    width: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    textFieldRef.current.getElementsByTagName(
                      "input"
                    )[0].value = "";
                    setValue(null);
                  }}
                >
                  <CloseX />
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Search"
          variant="outlined"
          onKeyUp={keyUp}
          ref={textFieldRef}
        />
      </div>
      {hasFocus
        ? listBox(
            groupedOptions,
            classes,
            getListboxProps,
            getOptionProps,
            props.searchItemsHeader,
            props.searchNoItemsHeader,
            props.zIndex,
            props.optionsValue
          )
        : null}
    </div>
  );
}

function listBox(
  groupedOptions,
  classes,
  getListboxProps,
  getOptionProps,
  searchItemsHeader,
  searchNoItemsHeader,
  zIndex,
  optionsValue
) {
  return groupedOptions.length > 0
    ? listOptions(
        classes,
        getListboxProps,
        groupedOptions,
        getOptionProps,
        searchItemsHeader,
        zIndex,
        optionsValue
      )
    : emptyListOptions(classes, getListboxProps, searchNoItemsHeader, zIndex);
}

function listOptions(
  classes,
  getListboxProps,
  groupedOptions,
  getOptionProps,
  searchItemsHeader,
  zIndex,
  optionsValue
) {
  return (
    <Box
      className={classes.listbox}
      {...getListboxProps()}
      style={{ zIndex, marginTop: "-12px" }}
    >
      <p>{searchItemsHeader}</p>
      <ul>
        {groupedOptions.map((option, index) => (
          <li
            {...getOptionProps({
              option,
              index,
            })}
          >
            {optionsValue ? option[optionsValue] : option}
          </li>
        ))}
      </ul>
    </Box>
  );
}

function emptyListOptions(
  classes,
  getListboxProps,
  searchNoItemsHeader,
  zIndex
) {
  return (
    <Box className={classes.listbox} {...getListboxProps()} style={{ zIndex }}>
      <p>{searchNoItemsHeader}</p>
    </Box>
  );
}
