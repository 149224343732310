import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import HoursOfOperationDataRow from "./HoursOfOperationDataRow";
import { TableCell } from "@material-ui/core";
import { DayOfWeek } from "./Util/dayOfWeek";

const useStyles = makeStyles(() => ({
  tableCell: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  tableHeader: {
    backgroundColor: "#d3d3d3",
  },
}));

export default function HoursOfOperationDataTable(props) {
  const classes = useStyles();

  const rowDayUpdated = (rowIndex, day) => {
    const tempData = new DayOfWeek();
    Object.assign(tempData, props.hoursOfOperationData[rowIndex]);
    tempData[day] = !tempData[day];
    props.dataChanged(tempData, rowIndex);
  };

  const rowStartTimeUpdated = (rowIndex, startTime) => {
    const tempData = props.hoursOfOperationData[rowIndex];
    tempData.startTime = startTime;
    props.dataChanged(tempData, rowIndex);
  };

  const rowEndTimeUpdated = (rowIndex, endTime) => {
    const tempData = props.hoursOfOperationData[rowIndex];
    tempData.endTime = endTime;
    props.dataChanged(tempData, rowIndex);
  };

  const deleteRow = (rowIndex) => {
    props.deleteRow(rowIndex);
  };

  const orderUp = (rowIndex) => {
    props.orderUp(rowIndex);
  };

  const orderDown = (rowIndex) => {
    props.orderDown(rowIndex);
  };

  return (
    <TableContainer
      component={Paper}
      style={{ marginTop: "2rem", width: "65%" }}
    >
      <Table className={`${classes.table} listOfHoo`}>
        <TableHead className={classes.tableHeader}>
          <TableRow className={`hooRow`}>
            <TableCell>Schedule</TableCell>
            <TableCell>TimeSlot</TableCell>
            <TableCell></TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ width: "750px" }}>
          {props.hoursOfOperationData.map((obj, index) => (
            <HoursOfOperationDataRow
              hoursOfOperationData={obj}
              index={index}
              dayUpdated={rowDayUpdated}
              startTimeUpdated={rowStartTimeUpdated}
              endTimeUpdated={rowEndTimeUpdated}
              errorMessage={props.errorMessages[index]}
              deleteRow={deleteRow}
              orderUp={orderUp}
              orderDown={orderDown}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
