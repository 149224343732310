import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { React } from "react";

export default function SubHeader(props) {
  return (
    <Box display="flex" justifyContent="space-between" mb="15px">
      <Box
        flexBasis="50%"
        display="flex"
        justifyContent="left"
        alignItems="center"
      >
        <Typography variant="h1" id="subHeader">
          {props.pageTitle}
        </Typography>
      </Box>
      <Box flexBasis="50%" textAlign="right">
        <Typography
          style={{ fontSize: "30px", fontWeight: "800" }}
          color="Primary"
        >
          {props.tenantName}
        </Typography>
      </Box>
    </Box>
  );
}
