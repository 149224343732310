import Box from "@material-ui/core/Box";
import ServiceRow from "./ServiceRow";

export default function ServiceTable(props) {
  return (
    <Box>
      {props.services
        .filter((service) => {
          if (props.filterToService) {
            return service.name === props.filterToService.name;
          }
          return true;
        })
        .map((service, index) => (
          <ServiceRow
            programsAndServicesConfig={props.programsAndServicesConfig}
            key={index}
            service={service}
            index={index}
            services={props.services}
            serviceChanged={props.serviceChanged}
            program={props.program}
            removeService={props.removeService}
            programServiceFeeAdjustment={props.programServiceFeeAdjustment}
          />
        ))}
    </Box>
  );
}
