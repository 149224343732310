import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import GlobalSearch from "./GlobalSearch";
import MainTable from "./MainTable";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import DisableAllServiceDialog from "./DisableAllServiceDialog";

const useStyles = makeStyles(() => ({
  main: {
    "& *:not(.fa):not(.fas):not(.far)": {
      fontFamily: "IDEMIA"
    }
  },
  header_2: {
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: "20px",
    letterSpacing: ".5px",
    marginBottom: "15px"
  }
}));

export default function ProgramsAndServices(props) {
  const classes = useStyles();
  const [
    showDisableAllServiceDialog,
    setShowDisableAllServiceDialog
  ] = useState(false);
  const [filterToProgram, setFilterToProgram] = useState(null);
  const [filterToService, setFilterToService] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [modifiedProgram, setModifiedProgram] = useState(null);
  const [programIndex, setProgramIndex] = useState(null);

  const globalSearchValueChanged = value => {
    if (value && value.type === "program") {
      setFilterToProgram(value);
      setFilterToService(null);
    } else if (value && value.type === "service") {
      setFilterToService(value);
      setFilterToProgram(null);
    } else {
      setFilterToProgram(null);
      setFilterToService(null);
    }
  };

  const getProgramsConfig = () => {
    return props.programsAndServicesConfig.programs;
  };

  const getServicesConfig = () => {
    return props.programsAndServicesConfig.services;
  };

  const programsConfig = getProgramsConfig();
  const servicesConfig = getServicesConfig();

  const programChanged = (newProgram, programIndex, status) => {
    setShowDisableAllServiceDialog(status);
    setShowModal(status);

    setProgramIndex(programIndex);

    /** This is the index of the program in programsAndServiceConfig */
    let index;
    if (props.programsAndServicesConfig.programs) {
      index = props.programsAndServicesConfig.programs.findIndex(
        program => program.name === newProgram.name
      );
    }

    if (newProgram.active) {
      if (props.programsAndServicesConfig.programs === undefined) {
        let tempModifiedPrograms = [];
        tempModifiedPrograms.push(newProgram);

        props.handleModifiedProgramsAndServiceConfig({
          programs: [...tempModifiedPrograms],
          services: []
        });
      } else if (index >= 0) {
        let tempModifiedPrograms = [
          ...props.programsAndServicesConfig.programs
        ];
        tempModifiedPrograms[index] = newProgram;

        props.handleModifiedProgramsAndServiceConfig({
          programs: [...tempModifiedPrograms],
          services: [...servicesConfig]
        });

        /** Issue happens here when a user selects a program, then selects a service, then select a program, a service is disappeared. */
      } else if (index === -1) {
        let tempModifiedPrograms = [
          ...props.programsAndServicesConfig.programs
        ];

        tempModifiedPrograms.push(newProgram);

        props.handleModifiedProgramsAndServiceConfig({
          programs: [...tempModifiedPrograms],
          services: [...servicesConfig]
        });
      }
    } else {
      let tempModifiedPrograms = [...props.programsAndServicesConfig.programs];
      setModifiedProgram(tempModifiedPrograms[index]);
      tempModifiedPrograms.splice(index, 1);

      props.handleModifiedProgramsAndServiceConfig({
        programs: [...tempModifiedPrograms],
        services: [...servicesConfig]
      });
    }
  };

  /** this method will add a service to programsAndServicesConfig
   * serviceToAddArray is the one you will be added.
   */

  const serviceChanged = (serviceToAddArray, newServiceName) => {
    /** Case 1 - When there is no service yet */
    if (!props.programsAndServicesConfig.services) {
      let tempModifiedProgramAndServiceConfig = {
        ...props.programsAndServicesConfig
      };
      tempModifiedProgramAndServiceConfig.services = [...serviceToAddArray];
      /** When there are programs in the config, and the no program */
      if (props.programsAndServicesConfig.programs) {
        tempModifiedProgramAndServiceConfig.programs = [
          ...props.programsAndServicesConfig.programs
        ];
      } else {
        tempModifiedProgramAndServiceConfig.programs = [];
      }
      props.handleModifiedProgramsAndServiceConfig(
        tempModifiedProgramAndServiceConfig
      );
    }

    // /** Case 2 - when there is a service in the config */
    else {
      let tempModifiedProgramAndServiceConfig = {
        ...props.programsAndServicesConfig
      };
      tempModifiedProgramAndServiceConfig.services = [
        ...tempModifiedProgramAndServiceConfig.services,
        ...serviceToAddArray
      ];

      tempModifiedProgramAndServiceConfig.services.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      /** When there is programs in the config */
      if (props.programsAndServicesConfig.programs) {
        tempModifiedProgramAndServiceConfig.programs = [
          ...props.programsAndServicesConfig.programs
        ];
      } else {
        tempModifiedProgramAndServiceConfig.programs = [];
      }
      props.handleModifiedProgramsAndServiceConfig(
        tempModifiedProgramAndServiceConfig
      );
    }
  };

  const serviceUpdated = (serviceToUpdateArray, type, programIndex) => {
    /** This is a new service when a service is editted or updated */

    if (type !== "removeService") {
      let tempModifiedPrograms = {
        ...props.programsAndServicesConfig
      };

      /** all services with a new one updated now */

      let output = tempModifiedPrograms.services.map(
        obj => serviceToUpdateArray.find(o => o.name === obj.name) || obj
      );

      /* Now I have to combine the serviceToUpdateArray to services*/
      props.handleModifiedProgramsAndServiceConfig({
        programs: [...tempModifiedPrograms.programs],
        services: [...output]
      });
    } else {
      /** Take care deletion here */
      let tempModifiedPrograms = {
        ...props.programsAndServicesConfig
      };

      let output = tempModifiedPrograms.services.filter(
        obj => obj.name !== serviceToUpdateArray.name
      );

      /** Now I have to move array of services with item to be deleted into all services */

      props.handleModifiedProgramsAndServiceConfig({
        programs: [...tempModifiedPrograms.programs],
        services: [...output]
      });
    }
  };

  const getServicesForGlobalSearch = () => {
    if (servicesConfig) {
      const services = servicesConfig.reduce((previous, service) => {
        return [
          ...previous,
          {
            name: service.name,
            type: "service"
          }
        ];
      }, []);
      return services;
    } else {
      return [];
    }
  };

  const closeHandler = () => {
    setShowModal(false);

    const tempProgram = {
      name: modifiedProgram.name,
      active: true,
      publishToWeb: false,
      outOfStateFees: false,
      serviceFeeAdjustment: 0
    };

    let tempModifiedPrograms = [...props.programsAndServicesConfig.programs];
    tempModifiedPrograms.push(tempProgram);

    props.handleModifiedProgramsAndServiceConfig({
      programs: [...tempModifiedPrograms],
      services: [...servicesConfig]
    });
  };

  const disableHandler = () => {
    setShowModal(false);

    let tempModifiedServices = [...props.programsAndServicesConfig.services];

    let output = tempModifiedServices.filter(
      service => service.programName !== modifiedProgram.name
    );

    props.handleModifiedProgramsAndServiceConfig({
      programs: [...props.programsAndServicesConfig.programs],
      services: [...output]
    });
  };

  return (
    <Box className={classes.main}>
      <h2 className={classes.header_2}>Programs</h2>
      <GlobalSearch
        style={{
          marginBottom: "21px",
          zIndex: "5"
        }}
        programsAvailableAndServicesEnabled={[
          ...props.availableProgramsAndServices.reduce((previous, program) => {
            return [
              ...previous,
              {
                name: program.name,
                type: "program"
              }
            ];
          }, []),
          ...getServicesForGlobalSearch()
        ].sort((first, second) => (first.name > second.name ? 1 : -1))}
        valueChanged={globalSearchValueChanged}
      />

      <MainTable
        style={{
          marginLeft: "6px",
          marginRight: "6x",
          zIndex: "1"
        }}
        availableProgramsAndServices={props.availableProgramsAndServices}
        programsAndServicesConfig={props.programsAndServicesConfig}
        programsCongfig={programsConfig}
        servicesCongfig={servicesConfig}
        programChanged={programChanged}
        serviceChanged={serviceChanged}
        serviceUpdated={serviceUpdated}
        filterToProgram={filterToProgram} // review later
        filterToService={filterToService} // review later
      />
      <Dialog open={showModal} style={{ zIndex: "1000" }}>
        <DisableAllServiceDialog
          open={showModal}
          closeHandler={closeHandler}
          disableHandler={disableHandler}
        />
      </Dialog>
    </Box>
  );
}
