import Divider from "@material-ui/core/Divider";
import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: "12px 0",
  },
}));

export default function CustomDivider(props) {
  const classes = useStyles();

  return <Divider className={classes.divider} orientation="horizontal" />;
}
