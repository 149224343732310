import { React, useState } from "react";
import Box from "@material-ui/core/Box";
import NavButtons from "./components/NavButtons";
import ProgramsAndServices from "./ProgramsAndServices";

const FutureProgramAvailability = (props) => {
  const [programsAndServicesConfig, setProgramsAndServicesConfig] = useState(
    props.programsAndServicesConfig
  );

  const handleNext = () => {
    props.handleNext(programsAndServicesConfig);
  };

  const handleModifiedProgramsAndServiceConfig = (
    programsAndServicesConfig
  ) => {
    setProgramsAndServicesConfig(programsAndServicesConfig);
  };


  const handleBack = () => {
    props.handleBack(programsAndServicesConfig);
  };

  return (
    <Box mx={1}>
      <ProgramsAndServices
        availableProgramsAndServices={props.availableProgramsAndServices}
        programsAndServicesConfig={programsAndServicesConfig}
        handleModifiedProgramsAndServiceConfig={
          handleModifiedProgramsAndServiceConfig
        }
      />
      <NavButtons
        handleLeftButtonClick={handleBack}
        leftButtonId={"backButton"}
        leftButtonDisabled={false}
        leftButtonText={"Back"}
        handleRightButtonClick={handleNext}
        rightButtonId={"nextButton"}
        rightButtonDisabled={false}
        rightButtonText={"Next"}
      />
    </Box>
  );
};

export default FutureProgramAvailability;
