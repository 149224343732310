import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { React } from "react";
import NavButtons from "./components/NavButtons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  refreshButton: {
    width: "175px",
  },
  addLocationButton: {
    width: "175px",
  },
  searchInput: {
    width: "400px",
  },
}));

export default function HomeSearch(props) {
  const classes = useStyles();
  return (
    <Box className={props.containerClassName}>
      <TextField
        required
        size="small"
        label="Location Id, City, State, Postal/Airport Code"
        variant="outlined"
        onChange={props.searchHandler}
        className={`${classes.searchInput}`}
        id="searchLocation"
        style={{ marginRight: "1rem" }}
      />
      <Box>
        <NavButtons
          handleLeftButtonClick={props.handleRefresh}
          leftButtonId={"locationRefreshButton"}
          leftButtonDisabled={false}
          leftButtonText={"Refresh"}
          leftButtonClassName={classes.refreshButton}
          handleRightButtonClick={null}
          rightButtonComponent={Link}
          rightButtonTo={`/AddNewLocation`}
          rightButtonId={"addLocationButton"}
          rightButtonDisabled={false}
          rightButtonText={"Add Location"}
          rightButtonClassName={classes.addLocationButton}
        />
      </Box>
    </Box>
  );
}
