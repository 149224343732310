import React from 'react';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { usePathPrefix } from "./context";

import '../styles/styles.scss';

const ModuleApp = (props) => {
  const pathPrefix = usePathPrefix();

  return (
    <Router basename={pathPrefix}>
      <App />
    </Router>
  );
};

export default ModuleApp;