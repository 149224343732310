import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import ProgramRowMainContent from "./ProgramRowMainContent";
import ServiceContent from "./ServiceContent";

const useStyles = makeStyles(() => ({
  main: {
    margin: "3px",
  },
}));

export default function ProgramRow(props) {
  const classes = useStyles();

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  const collapseChanged = () => {
    setCollapsed(!collapsed);
  };

  /* Todo review - DONE */
  const activeChanged = () => {
    if (props.programConfig) {
      const tempProgram = {};
      Object.assign(tempProgram, props.programConfig);
      tempProgram.active = !props.programConfig.active;

      props.programChanged(
          tempProgram,
          props.index,
          props.servicesConfig === undefined || props.servicesConfig.length === 0 ? false : true
      );
    } else {
      const tempProgram = {
        name: props.program.name,
        active: true,
        publishToWeb: false,
        outOfStateFees: false,
        serviceFeeAdjustment: 0,
      };

      props.programChanged(
          tempProgram,
          props.index,
          false
      );
    }
  };

  const outOfStateFeesChanged = () => {
    const tempProgram = {};
    Object.assign(tempProgram, props.programConfig);
    tempProgram.outOfStateFees = !props.programConfig.outOfStateFees;
    props.programChanged(tempProgram);
  };

  const serviceFeeAdjustmentChanged = (value) => {
    let newValue = value;
    if (value === null) {
      newValue = 0
    }
    const tempProgram = {};
    Object.assign(tempProgram, props.programConfig);
    tempProgram.serviceFeeAdjustment = newValue;
    props.programChanged(tempProgram);
  };

  const publishToWebChanged = () => {
    const tempProgram = {};
    Object.assign(tempProgram, props.programConfig);
    tempProgram.publishToWeb = !props.programConfig.publishToWeb;
    props.programChanged(tempProgram);
  };

  /** Todo review - this is the method to update any service
   *
   * newService is an object of ther service to be updated.
   *
   */
  const serviceChanged = (newService) => {
    const tempServices = [...props.servicesConfig];

    const indexOfService = props.servicesConfig.findIndex(
        (service) => service.name === newService.name
    );

    // this is where you assigned a new service to the current index
    tempServices[indexOfService] = {};

    tempServices[indexOfService] = { ...newService };
    /* tempProgram is a list of all services displayed*/

    props.serviceUpdated(tempServices);
  };

  /** Todo review */
  const removeService = (serviceIndex) => {
    const tempService = [];

    Object.assign(tempService, props.servicesConfig);
    let temp = tempService[serviceIndex];

    tempService.splice(serviceIndex, 1);

    props.serviceUpdated(temp, "removeService", props.index);
  };

  /** Put the code and display the service in UI */
  /** Todo review */
  const addService = (serviceToAddName) => {
    if (serviceToAddName) {
      let tempServices = [];

      tempServices.push({
        name: serviceToAddName.name,
        codeId: serviceToAddName.codeId,
        programName: props.program.name,
        includedExcluded: true,
        publishToWeb: false,
        outOfStateFees: false,
        serviceFeeAdjustment: null,
      });

      props.serviceChanged(tempServices, serviceToAddName);
    }
  };

  return (
      <Box style={props.style} className={classes.main}>
        <ProgramRowMainContent
            program={props.program}
            programConfig={props.programConfig}
            availableServices={
              props.program && props.program.services ? props.program.services : []
            }
            servicesConfig={
              props.servicesConfig
              ? [...new Set(props.servicesConfig.map((item) => item))]
              : []
            }
            index={props.index}
            collapsed={collapsed}
            onCollapseChanged={collapseChanged}
            activeChanged={activeChanged}
            publishToWebChanged={publishToWebChanged}
            outOfStateFeesChanged={outOfStateFeesChanged}
            serviceFeeAdjustmentChanged={serviceFeeAdjustmentChanged}
            showModal={props.showModal}
        />

        {/* Now working on the service */}
        <ServiceContent
            hidden={collapsed}
            availableServices={
              props.program && props.program.services ? props.program.services : []
            }
            servicesConfig={
              props.servicesConfig
              ? [...new Set(props.servicesConfig.map((item, key) => item))]
              : []
            }
            serviceChanged={serviceChanged}
            index={props.index}
            removeService={removeService}
            addService={addService}
            program={props.program}
            programsAndServicesConfig={props.programsAndServicesConfig}
            filterToService={props.filterToService}
            programServiceFeeAdjustment={
              props.programConfig ? props.programConfig.serviceFeeAdjustment : ""
            }
        />
      </Box>
  );
}
