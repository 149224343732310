import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { React, useEffect, useState } from "react";
import { DayOfWeek, dayOfWeekTemplate } from "./Util/dayOfWeek";
import { makeStyles } from "@material-ui/core/styles";
import {
  isEndTimeAfterStartTime,
  isOneOrMoreDaysChecked,
} from "./Util/validations";
import { DaysOfWeekButtons } from "./DaysofWeekButtons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { messages } from "../Constants/Message";
import { TableCell } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  buttonStyle: {
    borderRightColor: "blue",
  },
  error: {
    position: "absolute",
    color: "red",
    fontSize: "10px",
    padding: "3px 0 0",
    margin: "0",
  },
  tableCell: {
    borderBottom: "none",
    padding: "0 16px",
  },
}));

export default function HoursOfOperationNewRowControl(props) {
  const classes = useStyles();

  const [daysOfWeek, setDaysOfWeek] = useState(dayOfWeekTemplate());
  const [errorMessage, setErrorMessage] = useState("");

  const dayButtonHandler = (day) => {
    let temp = new DayOfWeek();
    Object.assign(temp, daysOfWeek);
    temp[day] = !temp[day];
    setDaysOfWeek(temp);
  };

  const startTimeHandler = (e) => {
    let temp = new DayOfWeek();
    Object.assign(temp, daysOfWeek);
    temp.startTime = e.target.value + "Z";
    setDaysOfWeek(temp);
  };

  useEffect(() => {
    controlIsValid();
  }, [daysOfWeek]);

  const endTimeHandler = (e) => {
    let temp = new DayOfWeek();
    Object.assign(temp, daysOfWeek);
    temp.endTime = e.target.value + "Z";
    setDaysOfWeek(temp);
  };

  const handleAdd = () => {
    if (controlIsValid()) {
      props.sendNewHoursRowDataToParent(daysOfWeek);
      setDaysOfWeek(dayOfWeekTemplate());
    }
  };

  const controlIsValid = () => {
    const endTimeAfterStartTimeValid = isEndTimeAfterStartTime(
      daysOfWeek.startTime,
      daysOfWeek.endTime
    );

    if (endTimeAfterStartTimeValid) {
      setErrorMessage("");
    } else {
      setErrorMessage(messages.endTimeAfterEndTime);
    }

    const atLeastOneDayChecked = isOneOrMoreDaysChecked(daysOfWeek);

    if (!atLeastOneDayChecked) {
      setErrorMessage(messages.oneDay);
    }

    return endTimeAfterStartTimeValid && atLeastOneDayChecked;
  };

  return (
    <Table style={{ width: "65%" }}>
      <TableBody style={{ width: "750px" }}>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <DaysOfWeekButtons
              daysOfWeek={daysOfWeek}
              dayButtonHandler={dayButtonHandler}
            />
            <p className={classes.error} id="defaultRowErrorMessage">
              {errorMessage}
            </p>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <form noValidate>
              <TextField
                style={{ width: "150px" }}
                id="startTime"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={startTimeHandler}
                value={daysOfWeek.startTime.replace("Z", "")}
              />
            </form>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <form noValidate>
              <TextField
                style={{ width: "150px" }}
                id="endTime"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={endTimeHandler}
                value={daysOfWeek.endTime.replace("Z", "")}
              />
            </form>
          </TableCell>
          <TableCell className={classes.tableCell} style={{ width: "230px" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.back}
              onClick={handleAdd}
              id="addButton"
            >
              Add
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
