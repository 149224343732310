import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import { TableCell, TextField } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { React, useEffect, useState } from "react";
import {
  isEndTimeAfterStartTime,
  isOneOrMoreDaysChecked,
} from "./Util/validations";
import { DaysOfWeekButtons } from "./DaysofWeekButtons";
import { messages } from "../Constants/Message";

const useStyles = makeStyles(() => ({
  error: {
    position: "absolute",
    color: "red",
    fontSize: "10px",
    padding: "3px 0 0",
    margin: "0",
  },
}));

export default function HoursOfOperationDataRow(props) {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    controlIsValid();
  }, [props.hoursOfOperationData]);

  const dayButtonHandler = (day) => {
    props.dayUpdated(props.index, day);
  };

  const startTimeHandler = (e) => {
    props.startTimeUpdated(props.index, e.target.value + "Z");
    controlIsValid();
  };

  const endTimeHandler = (e) => {
    props.endTimeUpdated(props.index, e.target.value + "Z");
    controlIsValid();
  };

  const controlIsValid = () => {
    const endTimeAfterStartTimeValid = isEndTimeAfterStartTime(
      props.hoursOfOperationData.startTime,
      props.hoursOfOperationData.endTime
    );

    if (endTimeAfterStartTimeValid) {
      setErrorMessage("");
    } else {
      setErrorMessage(messages.endTimeAfterEndTime);
    }

    const atLeastOneDayChecked = isOneOrMoreDaysChecked(
      props.hoursOfOperationData
    );

    if (!atLeastOneDayChecked) {
      setErrorMessage(messages.oneDay);
    }

    return endTimeAfterStartTimeValid && atLeastOneDayChecked;
  };

  return (
    <TableRow>
      <TableCell>
        <DaysOfWeekButtons
          daysOfWeek={props.hoursOfOperationData}
          dayButtonHandler={dayButtonHandler}
          rowIndex={props.index}
        />
        <p id={`hooRowErrorMessage-${props.index}`} className={classes.error}>
          {errorMessage ? errorMessage : props.errorMessage}
        </p>
      </TableCell>
      <TableCell>
        <form noValidate>
          <TextField
            style={{ width: "150px" }}
            id={`startTime-${props.index}`}
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={startTimeHandler}
            value={props.hoursOfOperationData.startTime.replace("Z", "")}
          />
        </form>
      </TableCell>
      <TableCell>
        <form noValidate>
          <TextField
            style={{ width: "150px" }}
            id={`endTime-${props.index}`}
            type="time"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={endTimeHandler}
            value={props.hoursOfOperationData.endTime.replace("Z", "")}
          />
        </form>
      </TableCell>
      <TableCell style={{ width: "230px" }}>
        <Button
          style={{ width: "5%" }}
          onClick={() => props.deleteRow(props.index)}
          id={`trash-${props.index}`}
        >
          <i className="fas fa-trash-alt fa-2x" />
        </Button>
        <ButtonGroup color="primary">
          <Button
            onClick={() => props.orderUp(props.index)}
            id={`up-${props.index}`}
          >
            <i className="fas fa-angle-up" />
          </Button>
          <Button
            onClick={() => props.orderDown(props.index)}
            id={`down-${props.index}`}
          >
            <i className="fas fa-chevron-down" />
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
}
