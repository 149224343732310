import Box from "@material-ui/core/Box";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { React, useRef, useState } from "react";
import FormValidation from "../FormValidation";
import WarningError from "../WarningError";
import { closureReasonArray } from "../Constants/ClosureReason";
import CustomSelect from "../components/CustomSelect";
import { stateArray } from "../stateArray";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  sectionContainer: {
    width: "580px",
    marginBottom: "15px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  sectionColumn: {
    marginRight: "45px",
    "&:last-child": {
      marginRight: "0",
    },
  },
  inputRow: {
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  inputColumn: {
    marginRight: "11px",
    "&:last-child": {
      marginRight: "0",
    },
  },
  formControl: {
    width: "100%",
  },
}));

export default function ClosureForm(props) {
  const classes = useStyles();
  const postalCodeInputRef = useRef();
  const cityInputRef = useRef();
  const addressLine1InputRef = useRef();
  const effectiveDateInputRef = useRef();
  const currentTime = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
  const [dirtyState, setDirtyState] = useState({
    addressLine1: false,
    addressLine2: false,
    city: false,
    state: false,
    postalCode: false,
    effectiveDate: false,
    closureReason: false,
  });

  const inputChangeHandler = (event) => {
    let value;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    props.setLocation({
      ...props.location,
      [event.target.name]: value,
    });
    setDirtyState({
      ...dirtyState,
      [event.target.name]: true,
    });
  };

  return (
    <Box className={classes.sectionContainer}>
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          flexBasis="100%"
          className={classes.sectionColumn}
        >
          <Box display="flex" flexDirection="row" className={classes.inputRow}>
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="50%"
              className={classes.inputColumn}
            >
              <TextField
                style={{ width: "100%" }}
                size="small"
                variant="outlined"
                label="Effective Date"
                type="date"
                value={props.location.effectiveDate}
                id="closureDate"
                name="effectiveDate"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: currentTime,
                }}
                onChange={inputChangeHandler}
                required
                error={
                  !FormValidation.effectiveDate(props.location.effectiveDate) &&
                  dirtyState.effectiveDate
                }
                helperText={WarningError.effectiveDate(
                  !FormValidation.effectiveDate(props.location.effectiveDate) &&
                    dirtyState.effectiveDate
                )}
                inputRef={effectiveDateInputRef}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="50%"
              className={classes.inputColumn}
            >
              <CustomSelect
                classes={classes}
                label={"Closure Reason"}
                required
                error={
                  !FormValidation.closureReason(props.location.description) &&
                  dirtyState.closureReason
                }
                value={props.location.description}
                onChange={inputChangeHandler}
                options={closureReasonArray.map((closureReason) => {
                  return {
                    value: closureReason.closureReason,
                    name: closureReason.closureReason,
                  };
                })}
                labelId={"closureReason"}
                selectId={"closureReason"}
                selectName={"description"}
                optionIdPrefix={"closureReason"}
                helperTextId={"closureReason-helper-text"}
                helperMessage={WarningError.closureReason(
                  !FormValidation.closureReason(props.location.description) &&
                    dirtyState.closureReason
                )}
                goBackOneInput={() => {
                  setTimeout(() => effectiveDateInputRef.current.focus(), 100);
                }}
                goForwardOneInput={() => {
                  setTimeout(() => addressLine1InputRef.current.focus(), 100);
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" className={classes.inputRow}>
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="50%"
              className={classes.inputColumn}
            >
              <TextField
                required
                size="small"
                label="Address 1"
                variant="outlined"
                inputProps={{ maxLength: 75 }}
                id="address1"
                name="addressLine1"
                value={props.location.addressLine1}
                onChange={inputChangeHandler}
                error={
                  !FormValidation.addressLine1(props.location.addressLine1) &&
                  dirtyState.addressLine1
                }
                helperText={WarningError.addressLine1(
                  !FormValidation.addressLine1(props.location.addressLine1) &&
                    dirtyState.addressLine1
                )}
                inputRef={addressLine1InputRef}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="50%"
              className={classes.inputColumn}
            >
              <TextField
                size="small"
                label="Address 2"
                variant="outlined"
                inputProps={{ maxLength: 75 }}
                id="address2"
                name="addressLine2"
                value={props.location.addressLine2}
                onChange={inputChangeHandler}
                error={
                  !FormValidation.addressLine2(props.location.addressLine2) &&
                  dirtyState.addressLine2
                }
                helperText={WarningError.addressLine2(
                  !FormValidation.addressLine2(props.location.addressLine2) &&
                    dirtyState.addressLine2
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" className={classes.inputRow}>
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="33.33%"
              className={classes.inputColumn}
            >
              <TextField
                required
                size="small"
                label="City"
                variant="outlined"
                inputProps={{ maxLength: 35 }}
                name="city"
                id="city"
                onChange={inputChangeHandler}
                value={props.location.city}
                error={
                  !FormValidation.city(props.location.city) && dirtyState.city
                }
                helperText={WarningError.city(
                  !FormValidation.city(props.location.city) && dirtyState.city
                )}
                inputRef={cityInputRef}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="33.33%"
              className={classes.inputColumn}
              maxWidth={"210px"}
            >
              <CustomSelect
                label={"State"}
                required
                classes={classes}
                error={
                  !FormValidation.state(props.location.state) &&
                  dirtyState.state
                }
                value={
                  stateArray.find((state) =>
                    state.state.startsWith(props.location.state)
                  ).state
                }
                onChange={inputChangeHandler}
                options={stateArray.map((state) => {
                  return {
                    value: state.state,
                    name: state.state,
                  };
                })}
                selectId={"state"}
                selectName={"state"}
                optionIdPrefix={"state"}
                helperTextId={"state-helper-text"}
                helperMessage={WarningError.state(
                  !FormValidation.state(props.location.state) &&
                    dirtyState.state
                )}
                goBackOneInput={() => {
                  setTimeout(() => cityInputRef.current.focus(), 100);
                }}
                goForwardOneInput={() => {
                  setTimeout(() => postalCodeInputRef.current.focus(), 100);
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flexBasis="33.33%"
              className={classes.inputColumn}
            >
              <TextField
                size="small"
                label="Postal Code"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                name="postalCode"
                id="postalCode"
                value={props.location.postalCode}
                required
                onChange={inputChangeHandler}
                error={
                  !FormValidation.postalCode(props.location.postalCode) &&
                  dirtyState.postalCode
                }
                helperText={WarningError.postalCode(
                  !FormValidation.postalCode(props.location.postalCode) &&
                    dirtyState.postalCode
                )}
                inputRef={postalCodeInputRef}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
