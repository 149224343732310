import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as US_FLAG } from "../img/UsFlag.svg";
import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(() => ({
  base: {
    width: "150px",
    "&.MuiOutlinedInput-adornedStart": {
      paddingLeft: "0",
    },
  },
}));

function isDollarAmount(value) {
  if (value === "") {
    return 0;
  } else if (value === "." || value === "-" || value === "-.") {
    return value;
  } else {
    const valNumber = parseFloat(value);
    return (
      value !== "" &&
      /^-?\d*\.?\d{0,2}$/.test(value) &&
      valNumber <= 1000000 &&
      valNumber >= -1000000
    );
  }
}

let hasFocus = false;

export default function FlagTextInput(props) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function computeShowValue(value) {
    if (value === null || value === undefined || value === "") {
      return "";
    } else if (!value) {
      return formatter.format(0);
    } else {
      return formatter.format(value);
    }
  }

  const computedShowValue = computeShowValue(props.value);

  const [showValue, setShowValue] = useState(computedShowValue);
  const classes = useStyles();

  const valueChanged = (event) => {
    if (isDollarAmount(event.target.value)) {
      setShowValue(event.target.value);
    } else if (isDollarAmount(event.target.value) === 0) {
      setShowValue(event.target.value);
    }
  };

  useEffect(() => {
    if (!hasFocus) {
      setShowValue(computeShowValue(props.value));
    }
  }, [props.value]);

  const focused = () => {
    setShowValue(props.value);
    hasFocus = true;
  };

  const focusLost = () => {
    if (showValue === null || showValue === "" || isNaN(showValue)) {
      setShowValue(null);
      props.valueChange(null)
    } else {
      setShowValue(computeShowValue(showValue));
      props.valueChange(parseFloat(showValue))
    }

    hasFocus = false;
  };

  return (
    <OutlinedInput
      className={classes.base}
      variant={"outlined"}
      margin={"dense"}
      startAdornment={
        <InputAdornment
          position="start"
        >
          <Box
            style={{
              height: "37px",
              width: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <US_FLAG style={{ height: "21px", width: "32px" }} />
          </Box>
        </InputAdornment>
      }
      disabled={props.disabled}
      value={showValue}
      placeholder={computeShowValue(props.placeholder)}
      onChange={valueChanged}
      onFocus={focused}
      onBlur={focusLost}
    />
  );
}
