import { React, useState } from "react";
import Box from "@material-ui/core/Box";
import HoursOfOperation from "./HoursOfOperation";
import NavButtons from "./components/NavButtons";

const EditFutureHoursOfLocation = (props) => {
  const [hoursOfOperationData, setHoursOfOperationData] = useState(props.hours);
  const [isValid, setIsValid] = useState(true);

  const onNext = () => {
    if (isValid) {
      props.handleNext(hoursOfOperationData);
    }
  };

  const onBack = () => {
    props.handleBack(hoursOfOperationData);
  };

  const hoursOfOperationDataChanged = (newData) => {
    setHoursOfOperationData(newData);
  };

  const isContentValid = (validState) => {
    setIsValid(validState);
  };

  return (
    <Box>
      <HoursOfOperation
        hoursOfOperationData={hoursOfOperationData}
        onHoursOfOperationDataChanged={hoursOfOperationDataChanged}
        isContentValidChange={isContentValid}
      />
      <NavButtons
        handleLeftButtonClick={onBack}
        leftButtonId={"backButton"}
        leftButtonDisabled={false}
        leftButtonText={"Back"}
        handleRightButtonClick={onNext}
        rightButtonId={"nextButton"}
        rightButtonDisabled={!isValid}
        rightButtonText={"Next"}
      />
    </Box>
  );
};

export default EditFutureHoursOfLocation;
