import Box from "@material-ui/core/Box";
import CustomCheckbox from "../components/CustomCheckbox";
import FlagTextInput from "../components/FlagTextInput";
import React from "react";
import StatusLabel from "../components/StatusLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  main: {
    fontFamily: "IDEMIA",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "14px",
    letterSpacing: ".5px",
    display: "flex",
    flexGrow: "1",
    flexShrink: "0",
    height: "53px",
    borderRadius: "4px",
  },
  child: {
    margin: "20px 0",
  },
  withCheckBox: {
    display: "flex",
    alignItems: "center",
  },
  withCheckBoxText: {
    marginTop: "4px",
  },
  enableAll: {
    flexBasis: "30%",
  },
  status: {
    flexBasis: "10%",
  },
  pubToWeb: {
    flexBasis: "20%",
  },
  outOfState: {
    flexBasis: "20%",
  },
  serviceFee: {
    flexBasis: "10%",
    margin: "8px 0",
    paddingRight: "5px",
  },
  trashCan: {
    flexBasis: "10%",
    fontSize: "12px",
    margin: "10px 0",
    color: "#430099",
    display: "flex",
    alignItems: "center",
  },
  disabledText: {
    color: "rgba(0,0,0,.26)",
  },
}));

export default function ServiceRow(props) {
  const classes = useStyles();

  const getServiceStatusIndicator = () => {
    if (props.service.includedExcluded) {
      return <StatusLabel status={"ENABLED"} />;
    } else {
      return <StatusLabel />;
    }
  };

  const activeChanged = () => {
    /* props.service - service name
       props.program.name - program name
       How to point to the service row you are refering
    */
    // let service = props.program.services.map(service => {
    //   return service.name
    // })

    const tempService = {};
    Object.assign(tempService, props.service);

    if (tempService.includedExcluded) {
      tempService.serviceFeeAdjustment = 0;
    } else {
      tempService.serviceFeeAdjustment = null;
    }

    tempService.includedExcluded = !tempService.includedExcluded;
    tempService.publishToWeb = false;
    tempService.outOfStateFees = false;
    tempService.serviceFeeAdjustment = null;

    props.serviceChanged(tempService);
  };

  const publishToWebChanged = () => {
    const tempService = {};
    Object.assign(tempService, props.service);
    tempService.publishToWeb = !tempService.publishToWeb;
    props.serviceChanged(tempService);
  };

  const outOfStateFeesChanged = () => {
    const tempService = {};
    Object.assign(tempService, props.service);
    tempService.outOfStateFees = !tempService.outOfStateFees;
    props.serviceChanged(tempService);
  };

  const serviceFeeAdjustmentChanged = (value) => {
    let newValue = 0 === parseFloat(value) ? null : value
    const tempService = {};
    Object.assign(tempService, props.service);
    tempService.serviceFeeAdjustment = newValue;
    props.serviceChanged(tempService);
  };

  const removeService = () => {
    props.removeService(props.index);
  };

  return (
    <Box className={classes.main}>
      <Box
        className={`${classes.child} ${classes.enableAll} ${classes.withCheckBox}`}
      >
        <CustomCheckbox
          checked={props.service ? props.service.includedExcluded : false}
          onChange={activeChanged}
        />
        <span className={classes.withCheckBoxText}>{props.service.name}</span>
      </Box>
      <Box className={`${classes.child} ${classes.status}`}>
        {getServiceStatusIndicator()}
      </Box>
      <Box
        className={`${classes.child} ${classes.pubToWeb} ${classes.withCheckBox}`}
      >
        <CustomCheckbox
          disabled={!props.service || !props.service.includedExcluded}
          checked={props.service ? props.service.publishToWeb : false}
          onChange={publishToWebChanged}
        />
        <span
          className={`${classes.withCheckBoxText} ${
            !props.service || !props.service.includedExcluded
              ? classes.disabledText
              : undefined
          }`}
        >
          Publish To Web
        </span>
      </Box>
      <Box
        className={`${classes.child} ${classes.outOfState} ${classes.withCheckBox}`}
      >
        <CustomCheckbox
          disabled={!props.service || !props.service.includedExcluded}
          checked={props.service ? props.service.outOfStateFees : false}
          onChange={outOfStateFeesChanged}
        />
        <span
          className={`${classes.withCheckBoxText} ${
            !props.service || !props.service.includedExcluded
              ? classes.disabledText
              : undefined
          }`}
        >
          Out of State Fees
        </span>
      </Box>
      <Box className={`${classes.serviceFee}`}>
        <FlagTextInput
          valueChange={serviceFeeAdjustmentChanged}
          value={
            props.service && props.service.includedExcluded
              ? props.service.serviceFeeAdjustment
              : null
          }
          disabled={!props.service || !props.service.includedExcluded}
          placeholder={props.programServiceFeeAdjustment && props.service.includedExcluded ? props.programServiceFeeAdjustment : 0}
        />
      </Box>
      <Box className={`${classes.trashCan}`}>
        <i onClick={removeService} className="fas fa-trash fa-2x" />
      </Box>
    </Box>
  );
}
