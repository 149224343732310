import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CustomDivider from "../components/CustomDivider";
import { Star } from "@material-ui/icons";
import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  sectionContainer: {
    marginBottom: "25px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  mainColumn: {
    marginRight: "45px",
    "&:last-child": {
      marginRight: "0",
    },
  },
  sectionHeader: {
    marginBottom: "15px",
  },
  itemHeader: {
    marginBottom: "1px",
    fontWeight: "600",
    fontSize: "14px",
  },
  item: {
    color: "#676767",
  },
  sectionRow: {
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));

export default function ClosureReviewContent(props) {
  const classes = useStyles();

  const getValueOrNa = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "N/A";
    } else {
      return value;
    }
  };

  const getUriEncodedAddress = () => {
    return encodeURIComponent(
      `${props.locationInfo.addressLine1}, ${
        props.locationInfo.city
      }, ${props.locationInfo.state.substring(0, 3).trim()} ${
        props.locationInfo.postalCode
      }`
    );
  };

  return (
    <Box display={"flex"} flexDirection={"row"}>
      <Box flexBasis={"100%"} className={classes.mainColumn}>
        <Typography variant="h2">Location Information</Typography>
        <CustomDivider />
        <Box display={"flex"} flexDirection={"row"}>
          <Box display={"flex"} flexDirection={"column"} flexBasis={"50%"}>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                General Information
              </Typography>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Box flexBasis={"7%"}>
                      <Star style={{ marginTop: "-1px" }} />
                    </Box>
                    <Box flexBasis={"93%"}>
                      <Typography className={classes.itemHeader} variant="h5">
                        Closure Date:
                      </Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Box flexBasis={"7%"}></Box>
                    <Box flexBasis={"93%"}>
                      <Typography className={classes.item} variant="body1">
                        {getValueOrNa(props.locationInfo.effectiveDate)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      ECR (Enrollment Center Roster):{" "}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.locationId)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Location Name:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.locationName)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Closure Reason:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.description)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} flexBasis={"50%"}>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                Forwarding Address Information
              </Typography>
              <Box display={"flex"} flexDirection={"row"}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                  justifyContent="space-between"
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.addressLine1)}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.addressLine2)}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.city)},{" "}
                      {getValueOrNa(
                        props.locationInfo.state.substring(0, 3).trim()
                      )}{" "}
                      {getValueOrNa(props.locationInfo.postalCode)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionContainer}
              style={{ height: "308px" }}
              border={1}
            >
              <iframe
                title={"Location Map"}
                style={{
                  width: "calc(100% - 1px)",
                  height: "100%",
                  border: 0,
                }}
                id="viewMapIframe"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDJ5S8xj30pChADEvmps9UJaIYRajdgb8s&q=${getUriEncodedAddress()}`}
              ></iframe>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
