import React, { createContext, useContext } from 'react';

// IsModuleContext
const IsModuleContext = createContext(false);

// hook for accessing the isModule flag
const useIsModule = () => useContext(IsModuleContext);

// initial PathPrefix (empty string)
const initialPathPrefix = '';

// PathPrefixContext
const PathPrefixContext = createContext(initialPathPrefix);

// hook for accessing the PathPrefix
const usePathPrefix = () => useContext(PathPrefixContext);

const AppProvider = ({
  isModule,
  pathPrefix,
  children
}) => {
  return (
    <IsModuleContext.Provider value={isModule}>
      <PathPrefixContext.Provider value={pathPrefix}>
        {children}
      </PathPrefixContext.Provider>
    </IsModuleContext.Provider>
  );
};

export {
  AppProvider,
  useIsModule,
  usePathPrefix
};
