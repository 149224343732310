export const stateAbbreviationArray = [
  { state: "AK" },
  { state: "AZ" },
  { state: "AR" },
  { state: "CA" },
  { state: "CO" },
  { state: "CT" },
  { state: "DE" },
  { state: "DC" },
  { state: "FL" },
  { state: "GA" },
  { state: "HI" },
  { state: "ID" },
  { state: "IL" },
  { state: "IN" },
  { state: "IA" },
  { state: "KS" },
  { state: "KY" },
  { state: "LA" },
  { state: "ME" },
  { state: "MD" },
  { state: "MA" },
  { state: "MI" },
  { state: "MN" },
  { state: "MS" },
  { state: "MO" },
  { state: "MT" },
  { state: "NV" },
  { state: "NH" },
  { state: "NJ" },
  { state: "NM" },
  { state: "NY" },
  { state: "NC" },
  { state: "ND" },
  { state: "OH" },
  { state: "OK" },
  { state: "OR" },
  { state: "PA" },
  { state: "RI" },
  { state: "SC" },
  { state: "SD" },
  { state: "TN" },
  { state: "TX" },
  { state: "UT" },
  { state: "VT" },
  { state: "VA" },
  { state: "WA" },
  { state: "WV" },
  { state: "WI" },
  { state: "WY" },
  { state: "PR" },
  { state: "VI" },
  { state: "BK" },
  { state: "HO" },
  { state: "JR" },
  { state: "JI" },
  { state: "KI" },
  { state: "MW" },
  { state: "VL" },
  { state: "PL" },
  { state: "WK" },
  { state: "JI" },
  { state: "JI" },
  { state: "CZ" },
];

export const stateArray = [
  { state: "AL - Alabama" },
  { state: "AK - Alaska" },
  { state: "AZ - Arizona" },
  { state: "AR - Arkansas" },
  { state: "CA - California" },
  { state: "CO - Colorado" },
  { state: "CT - Connecticut" },
  { state: "DE - Delaware" },
  { state: "DC - District of Columbia" },
  { state: "FL - Florida" },
  { state: "GA - Georgia" },
  { state: "HI - Hawaii" },
  { state: "ID - Idaho" },
  { state: "IL - Illinois" },
  { state: "IN - Indiana" },
  { state: "IA - Iowa" },
  { state: "KS - Kansas" },
  { state: "KY - Kentucky" },
  { state: "LA - Louisiana" },
  { state: "ME - Maine" },
  { state: "MD - Maryland" },
  { state: "MA - Massachusetts" },
  { state: "MI - Michigan" },
  { state: "MN - Minnesota" },
  { state: "MS - Mississippi" },
  { state: "MO - Missouri" },
  { state: "MT - Montana" },
  { state: "NE - Nebraska" },
  { state: "NV - Nevada" },
  { state: "NH - New Hampshire" },
  { state: "NJ - New Jersey" },
  { state: "NM - New Mexico" },
  { state: "NY - New York" },
  { state: "NC - North Carolina" },
  { state: "ND - North Dakota" },
  { state: "OH - Ohio" },
  { state: "OK - Oklahoma" },
  { state: "OR - Oregon" },
  { state: "PA - Pennsylvania" },
  { state: "RI - Rhode Island" },
  { state: "SC - South Carolina" },
  { state: "SD - South Dakota" },
  { state: "TN - Tennessee" },
  { state: "TX - Texas" },
  { state: "UT - Utah" },
  { state: "VT - Vermont" },
  { state: "VA - Virginia" },
  { state: "WA - Washington State" },
  { state: "WV - West Virginia" },
  { state: "WI - Wisconsin" },
  { state: "WY - Wyoming" },
  { state: "PR - Puerto Rico (U.S. Ter)" },
  { state: "VI - Virgin Islands (U.S. Ter)" },
  { state: "AM - American Samoa (U.S. Ter)" },
  { state: "GM - Guam (U.S. Ter)" },
  { state: "BK - Baker Island (U.S. Ter)" },
  { state: "HO - Howland Island (U.S. Ter)" },
  { state: "JR - Jarvis Island (U.S. Ter)" },
  { state: "JI - Johnston Atoll (U.S. Ter)" },
  { state: "KI - Kingman Reef (U.S. Ter)" },
  { state: "Northern Mariana Islands (U.S. Ter)" },
  { state: "MW - Midway Atoll (U.S. Ter)" },
  { state: "VL - Navassa Island (U.S. Ter)" },
  { state: "PL - Palmyra Atoll (U.S. Ter)" },
  { state: "WK - Wake Atoll (U.S. Ter)" },
  { state: "AA - Armed Forces Americas" },
  { state: "AE - Armed Forces Africa, Canada, Europe, Middle East" },
  { state: "AP - Armed Forces Pacific" },
  { state: "JIN - North Island (U.S. Ter)" },
  { state: "JIS - Sand Island (U.S. Ter)" },
  { state: "CZ - Panama Canal Zone (U.S. Ter)" },
];

export const mapState = [
  { AL: "AL - Alabama" },
  { AK: "AK - Alaska" },
  { AZ: "AZ - Arizona" },
  { AR: "AR - Arkansas" },
  { CA: "CA - California" },
  { CO: "CO - Colorado" },
  { CT: "CT - Connecticut" },
  { DE: "DE - Delaware" },
  { DC: "DC - District of Columbia" },
  { FL: "FL - Florida" },
  { GA: "GA - Georgia" },
  { HI: "HI - Hawaii" },
  { ID: "ID - Idaho" },
  { IL: "IL - Illinois" },
  { IN: "IN - Indiana" },
  { IA: "IA - Iowa" },
  { KS: "KS - Kansas" },
  { KY: "KY - Kentucky" },
  { LA: "LA - Louisiana" },
  { ME: "ME - Maine" },
  { MD: "MD - Maryland" },
  { MA: "MA - Massachusetts" },
  { MI: "MI - Michigan" },
  { MN: "MN - Minnesota" },
  { MS: "MS - Mississippi" },
  { MO: "MO - Missouri" },
  { MT: "MT - Montana" },
  { NE: "NE - Nebraska" },
  { NV: "NV - Nevada" },
  { NH: "NH - New Hampshire" },
  { NJ: "NJ - New Jersey" },
  { NM: "NM - New Mexico" },
  { NY: "NY - New York" },
  { NC: "NC - North Carolina" },
  { ND: "ND - North Dakota" },
  { OH: "OH - Ohio" },
  { OK: "OK - Oklahoma" },
  { OR: "OR - Oregon" },
  { PA: "PA - Pennsylvania" },
  { RI: "RI - Rhode Island" },
  { SC: "SC - South Carolina" },
  { SD: "SD - South Dakota" },
  { TN: "TN - Tennessee" },
  { TX: "TX - Texas" },
  { UT: "UT - Utah" },
  { VT: "VT - Vermont" },
  { VA: "VA - Virginia" },
  { WA: "WA - Washington State" },
  { WV: "WV - West Virginia" },
  { WI: "WI - Wisconsin" },
  { WY: "WY - Wyoming" },
  { PR: "PR - Puerto Rico (U.S. Ter)" },
  { VI: "VI - Virgin Islands (U.S. Ter)" },
  { AM: "AM - American Samoa (U.S. Ter)" },
  { BK: "BK - Baker Island (U.S. Ter)" },
  { GM: "GM - Guam (U.S. Ter)" },
  { HO: "HO - Howland Island (U.S. Ter)" },
  { JR: "JR - Jarvis Island (U.S. Ter)" },
  { JI: "JI - Johnston Atoll (U.S. Ter)" },
  { KI: "KI - Kingman Reef (U.S. Ter)" },
  { MK: "MK - Northern Mariana Islands (U.S. Ter)" },
  { MW: "MW - Midway Atoll (U.S. Ter)" },
  { VL: "VL - Navassa Island (U.S. Ter)" },
  { PL: "PL - Palmyra Atoll (U.S. Ter)" },
  { WK: "WK - Wake Atoll (U.S. Ter)" },
  { AA: "AA - Armed Forces Americas" },
  { AE: "AE - Armed Forces Africa Canada Europe Middle East" },
  { AP: "AP - Armed Forces Pacific" },
  { JIN: "JIN - North Island (U.S. Ter)" },
  { JIS: "JIS - Sand Island (U.S. Ter)" },
  { CZ: "CZ - Panama Canal Zone (U.S. Ter)" },
];
