import moment from "moment";
import { masterWeek } from "../../HourConstant";

export function isEndTimeAfterStartTime(startTime, endTime) {
  return endTime > startTime;
}

export function hasDaysOverlap(daysOfWeek, checkDaysOfWeek) {
  for (const i in masterWeek) {
    if (daysOfWeek[masterWeek[i]] && checkDaysOfWeek[masterWeek[i]]) {
      return true;
    }
  }

  return false;
}

export function hasTimeOverlap(
  startTime,
  endTime,
  checkStartTime,
  checkEndTime
) {
  const mStartTime = moment(startTime, "hh:mm");
  const mEndTime = moment(endTime, "hh:mm");
  const mCheckStartTime = moment(checkStartTime, "hh:mm");
  const mCheckEndTime = moment(checkEndTime, "hh:mm");

  return (
    mStartTime.isSame(mCheckStartTime) ||
    mStartTime.isSame(mCheckEndTime) ||
    mEndTime.isSame(mCheckStartTime) ||
    mEndTime.isSame(mCheckEndTime) ||
    (mStartTime.isAfter(mCheckStartTime) &&
      mStartTime.isBefore(mCheckEndTime)) ||
    (mEndTime.isAfter(mCheckStartTime) && mEndTime.isBefore(mCheckEndTime))
  );
}

export function isOneOrMoreDaysChecked(daysOfWeek) {
  let isChecked = false;

  for (const index in masterWeek) {
    if (daysOfWeek[masterWeek[index]]) {
      isChecked = daysOfWeek[masterWeek[index]];
      break;
    }
  }

  return isChecked;
}
