import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CustomDivider from "../components/CustomDivider";
import { Star } from "@material-ui/icons";
import CustomTable from "../components/CustomTable";
import {
  getDaysOfWeekWithTimeFormatted,
  getTableArrayDaysOfWeekWithTime,
} from "../HoursOfOperation/Util/dayOfWeek";
import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  sectionContainer: {
    marginBottom: "25px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  mainColumn: {
    marginRight: "45px",
    "&:last-child": {
      marginRight: "0",
    },
  },
  sectionHeader: {
    marginBottom: "15px",
  },
  itemHeader: {
    marginBottom: "1px",
    fontWeight: "600",
    fontSize: "14px",
  },
  item: {
    color: "#676767",
  },
  sectionRow: {
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));

export default function ReviewContent(props) {
  const classes = useStyles();

  const daysOfWeekWithTime = getDaysOfWeekWithTimeFormatted(
    props.hoursOfOperation
  );

  const getValueOrNa = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "N/A";
    } else {
      return value;
    }
  };

  const getUriEncodedAddress = () => {
    return encodeURIComponent(
      `${props.locationInfo.addressLine1}, ${
        props.locationInfo.city
      }, ${props.locationInfo.state.substring(0, 3).trim()} ${
        props.locationInfo.postalCode
      }`
    );
  };

  return (
    <Box display={"flex"} flexDirection={"row"}>
      <Box flexBasis={"60%"} className={classes.mainColumn}>
        <Typography variant="h2">Location Information</Typography>
        <CustomDivider />
        <Box display={"flex"} flexDirection={"row"}>
          <Box display={"flex"} flexDirection={"column"} flexBasis={"50%"}>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                General Information
              </Typography>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Box flexBasis={"7%"}>
                      <Star style={{ marginTop: "-1px" }} />
                    </Box>
                    <Box flexBasis={"93%"}>
                      <Typography className={classes.itemHeader} variant="h5">
                        Effective Date:
                      </Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Box flexBasis={"7%"}></Box>
                    <Box flexBasis={"93%"}>
                      <Typography className={classes.item} variant="body1">
                        {getValueOrNa(props.locationInfo.effectiveDate)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      ECR (Enrollment Center Roster):{" "}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.locationId)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Location Name:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.locationName)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Location Type:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.locationType)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      BSSR ID (Business Service Site Reference):
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.bssrid)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      More Details:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.description)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                Partner Information
              </Typography>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Partner Type:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.partnerType)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Partner Name:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.partnerName)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Partner Location ID:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.partnerLocationId)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  {props.locationInfo.partnerType === "Host" ? (
                    <Box display={"flex"} flexDirection={"row"}>
                      <Typography className={classes.itemHeader} variant="h5">
                        Host Name:
                      </Typography>
                    </Box>
                  ) : null}
                  {props.locationInfo.partnerType === "Host" ? (
                    <Box display={"flex"} flexDirection={"row"}>
                      <Typography className={classes.item} variant="body1">
                        {getValueOrNa(props.locationInfo.hostName)}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                Special Location Information
              </Typography>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Special Location Fee:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.specialLocationFee)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Access Code:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(
                        !props.locationInfo.specialLocationAccessCode
                          ? null
                          : props.locationInfo.specialLocationAccessCode.replace(
                              "SP-",
                              ""
                            )
                      ) === "N/A"
                        ? "N/A"
                        : "SP-" +
                          props.locationInfo.specialLocationAccessCode.replace(
                            "SP-",
                            ""
                          )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                Service Availability
              </Typography>
              <Box
                className={classes.sectionRow}
                display={"flex"}
                flexDirection={"row"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.itemHeader} variant="h5">
                      Service Availability:
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"}>
                    {!props.locationInfo.appointments &&
                    !props.locationInfo.sameDayAppointments &&
                    !props.locationInfo.oneVisitEligible &&
                    !props.locationInfo.walkins ? (
                      <Typography className={classes.item} variant="body1">
                        N/A
                      </Typography>
                    ) : null}
                    {props.locationInfo.appointments ? (
                      <Typography className={classes.item} variant="body1">
                        Appointments
                      </Typography>
                    ) : null}
                    {props.locationInfo.sameDayAppointments ? (
                      <Typography className={classes.item} variant="body1">
                        Same-Day Appointments
                      </Typography>
                    ) : null}
                    {props.locationInfo.oneVisitEligible ? (
                      <Typography className={classes.item} variant="body1">
                        OneVisit Eligible
                      </Typography>
                    ) : null}
                    {props.locationInfo.walkins ? (
                      <Typography className={classes.item} variant="body1">
                        Walk-Ins
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} flexBasis={"50%"}>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                Address Information
              </Typography>
              <Box display={"flex"} flexDirection={"row"}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                  justifyContent="space-between"
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.building)}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.addressLine1)}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.addressLine2)}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Typography className={classes.item} variant="body1">
                      {getValueOrNa(props.locationInfo.city)},{" "}
                      {getValueOrNa(
                        props.locationInfo.state.substring(0, 3).trim()
                      )}{" "}
                      {getValueOrNa(props.locationInfo.postalCode)}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flexBasis={"50%"}
                >
                  <Box className={classes.sectionRow}>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Typography className={classes.itemHeader} variant="h5">
                        Airport Code:
                      </Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Typography className={classes.item} variant="body1">
                        {getValueOrNa(props.locationInfo.airportCode)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.sectionRow}>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Typography className={classes.itemHeader} variant="h5">
                        Timezone:
                      </Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                      <Typography className={classes.item} variant="body1">
                        {getValueOrNa(props.locationInfo.timezone)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.sectionContainer}
              style={{ height: "308px" }}
              border={1}
            >
              <iframe
                title={"Location Map"}
                style={{
                  width: "calc(100% - 1px)",
                  height: "100%",
                  border: 0,
                }}
                id="viewMapIframe"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDJ5S8xj30pChADEvmps9UJaIYRajdgb8s&q=${getUriEncodedAddress()}`}
              ></iframe>
            </Box>
            <Box
              className={classes.sectionContainer}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography className={classes.sectionHeader} variant="h3">
                Hours Of Operation
              </Typography>
              {daysOfWeekWithTime.length === 0 ? (
                <Typography className={classes.item} variant="body1">
                  N/A
                </Typography>
              ) : (
                <CustomTable
                  headerVals={["Days", "From", "To"]}
                  rows={getTableArrayDaysOfWeekWithTime(daysOfWeekWithTime)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flexBasis={"40%"} className={classes.mainColumn}>
        <Typography variant="h2">Program Availability</Typography>
        <CustomDivider />
        <Box>
          <Typography className={classes.sectionHeader} variant="h3">
            Coming Soon
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
