import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ServiceSearch from "./ServiceSearch";
import ServiceTable from "./ServiceTable";

const useStyles = makeStyles(() => ({
  main: {
    paddingLeft: "71px",
    marginBottom: "70px",
    marginTop: "29px",
  },
  header: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "-0.15px",
  },
  body: {
    fontSize: "10px",
    letterSpacing: "0.5px",
    color: "#676767",
  },
  message: {
    fontSize: "14px",
    letterSpacing: "0.5px",
    color: "#676767",
  },
  hr: {
    border: "none",
    borderTop: "1px solid #E0E0E0",
  },
}));

/** issue with ServiceSearch */
export default function ServiceContent(props) {
  const classes = useStyles();

  /** props.servicesConfig - the ones belonging to a program
   * serviceName - the service you will be added.
   *
   */
  const serviceSelected = (serviceName) => {
    props.addService(serviceName);
  };

  return (
    <Box hidden={props.hidden} className={classes.main}>
      <ServiceSearch
        style={{ marginBottom: "22px" }}
        availableServices={props.availableServices.filter(
          (service) =>
            !props.servicesConfig.find(
              (modifiedService) => modifiedService.name === service.name
            )
        )}
        valueChanged={serviceSelected}
      />
      <h3 className={classes.header}>EDITED SERVICES</h3>
      <p className={classes.body}>
        Edit individual services by adding them from the dropdown. Overrides to
        individual service setting(s), apply only to the corresponding service.
      </p>
      <hr className={classes.hr} />
      {props.servicesConfig.length === 0 ? (
        <p className={classes.message}>No Services Selected.</p>
      ) : (
        <ServiceTable
          programsAndServicesConfig={props.programsAndServicesConfig}
          services={props.servicesConfig}
          program={props.program}
          serviceChanged={props.serviceChanged}
          removeService={props.removeService}
          filterToService={props.filterToService}
          programServiceFeeAdjustment={props.programServiceFeeAdjustment}
        />
      )}
    </Box>
  );
}
