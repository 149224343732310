export function responseHandler(response) {
  if (response.status === 404) {
    throw new Error("404");
  } else if (
    response.status >= 400 &&
    response.status <= 499 &&
    response.status !== 404
  ) {
    throw new Error("4xx");
  } else if (response.status >= 500 && response.status <= 599) {
    throw new Error("5xx");
  } else if (response.status === 200) {
    return response.json();
  } else {
    throw new Error("non-200");
  }
}
