import React from "react";
import ReactDOM from "react-dom";
import { AppProvider } from './context';
import ModuleApp from './ModuleApp';

ReactDOM.render(
  <AppProvider isModule={false} pathPrefix="">
    <ModuleApp />
  </AppProvider>,
  document.getElementById("locationServices")
);
