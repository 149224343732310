import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { React, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

let justClosed = false;

const useStyles = makeStyles((theme) => ({
  label: {
    background: "white",
    padding: "0 8px",
    marginLeft: "-5px",
  },
  select: {
    "& .MuiSvgIcon-root": {
      top: "calc(50% - 9px)",
    },
  },
  helperText: {
    marginTop: 0,
  },
}));

export default function CustomSelect(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const selectInputRef = useRef();

  useEffect(() => {
    try {
      selectInputRef.current.focus();
    } catch {}
  }, [props.setFocus]);

  const handleClose = (event) => {
    setOpen(false);
    justClosed = true;
    setTimeout(() => {
      justClosed = false;
    }, 100);

    if (
      event.nativeEvent.type === "keydown" &&
      event.key === "Tab" &&
      event.shiftKey
    ) {
      props.goBackOneInput && props.goBackOneInput();
    } else if (event.nativeEvent.type === "keydown" && event.key === "Tab") {
      props.goForwardOneInput && props.goForwardOneInput();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFocus = () => {
    if (!justClosed) {
      setOpen(true);
    }
  };

  return (
    <FormControl
      className={props.classes.formControl}
      error={props.error}
      size="small"
      variant="outlined"
    >
      <InputLabel
        shrink
        id={props.labelId}
        required={props.required}
        className={classes.label}
      >
        {props.label}
      </InputLabel>
      <Select
        className={classes.select}
        labelId={props.labelId}
        required={props.required}
        id={props.selectId}
        displayEmpty
        error={props.error}
        value={props.value}
        name={props.selectName}
        onChange={props.onChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        onFocus={handleFocus}
        inputRef={selectInputRef}
        inputProps={{ style: { boxSizing: "border-box" } }}
      >
        {props.options.map((item, index) => {
          return (
            <MenuItem
              value={item.value}
              id={`${props.optionIdPrefix}-${index}`}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText id={props.helperTextId} className={classes.helperText}>
        {props.error && props.helperMessage}
      </FormHelperText>
    </FormControl>
  );
}
