import { React, useState } from "react";
import Box from "@material-ui/core/Box";
import NavButtons from "./components/NavButtons";
import ProgramsAndServices from "./ProgramsAndServices";

const ProgramAvailability = (props) => {
  /* This variable will be dynamically changed when a user makes any change to the services or program, and this variable will be set to an object variable 
  set in a parent, this object will be sent to the backend. */
  const [programsAndServicesConfig, setProgramsAndServicesConfig] = useState(
    props.programsAndServicesConfig
  );

  const handleNext = () => {
    props.handleNext(programsAndServicesConfig);
  };

  const handleModifiedProgramsAndServiceConfig = (
    programsAndServicesConfig
  ) => {
    setProgramsAndServicesConfig(programsAndServicesConfig);
  };

  const handleBack = () => {
    props.handleBack(programsAndServicesConfig);
  };

  return (
    <Box mx={1}>
      <ProgramsAndServices
        availableProgramsAndServices={props.availableProgramsAndServices}
        programsAndServicesConfig={programsAndServicesConfig}
        handleModifiedProgramsAndServiceConfig={
          handleModifiedProgramsAndServiceConfig
        }
      />

      <NavButtons
        handleLeftButtonClick={handleBack}
        leftButtonId={"backButton"}
        leftButtonDisabled={false}
        leftButtonText={"Back"}
        handleRightButtonClick={handleNext}
        rightButtonId={"nextButton"}
        rightButtonDisabled={false}
        rightButtonText={"Next"}
      />
    </Box>
  );
};

export default ProgramAvailability;
