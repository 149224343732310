import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CloseX from "../svg/CloseX";
import { Collapse } from "@material-ui/core";
import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  alertAction: {
    fontSize: "20px",
  },
}));

export default function CustomAlert(props) {
  const classes = useStyles();

  return (
    <Collapse in={props.alertOpen}>
      <Alert
        severity={props.severity ? props.severity : "error"}
        color={props.color ? props.color : "error"}
        action={
          <Button
            onClick={() => {
              props.setAlertOpen(false);
            }}
          >
            <CloseX className={classes.alertAction} />
          </Button>
        }
      >
        {props.alertMessage}
      </Alert>
    </Collapse>
  );
}
