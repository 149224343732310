import { React, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { getDomain } from "./Domain";
import { responseHandler } from "./utilities/HttpResponseHandler";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoogleMapDialog from "./components/GoogleMapDialog";
import CustomDialog from "./components/CustomDialog";
import SubHeader from "./components/SubHeader";
import Divider from "@material-ui/core/Divider";
import HomeSearch from "./HomeSearch";
import CustomAlert from "./components/CustomAlert";
import CustomTable from "./components/CustomTable";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {
  getDaysOfWeekWithTimeFormatted,
  getRowsForCustomTable,
  getTableArrayDaysOfWeekWithTime
} from "./HoursOfOperation/Util/dayOfWeek";
import NavButtons from "./components/NavButtons";

const useStyles = makeStyles(theme => ({
  homeSearchContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "15px 0"
  },
  addLocationButton: {
    width: "175px"
  }
}));

const HomeLocation = () => {
  const classes = useStyles();
  const [locationArray, setLocationArray] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [mapAddress, setMapAddress] = useState("");
  const [error, setError] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  /* This will call an API to get all locations at the beginning  */
  useEffect(() => {
    const accessToken = oktaAuth.getAccessToken();
    if (authState.isAuthenticated) {
      setLoading(true);
      fetch(`${getDomain()}tenants/UE/location`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then(response => {
          return responseHandler(response);
        })
        .then(locationArray => {
          setLocationArray(locationArray);
          setLoading(false);
        })
        .catch(e => {
          if (e.message === "404") {
            setError("404");
          } else if (e.message === "4xx") {
            setError("4xx");
          } else if (e.message === "5xx") {
            setError("5xx");
          } else {
            setError("timeout");
          }
          setLoading(false);
        });
    }
    return () => {};
  }, [authState, oktaAuth]);

  const searchHandler = e => {
    setFilter(e.target.value);
  };

  const handleRefresh = () => {
    setLoading(true);
    const accessToken = oktaAuth.getAccessToken();
    fetch(`${getDomain()}tenants/UE/location`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        return responseHandler(response);
      })
      .then(locationArray => {
        setLocationArray(locationArray);
        setLoading(false);
      })
      .catch(e => {
        if (e.message === "404") {
          setError("404");
        } else if (e.message === "4xx") {
          setError("4xx");
        } else if (e.message === "5xx") {
          setError("5xx");
        } else {
          setError("timeout");
        }
        setLoading(false);
      });
  };

  const viewMapClicked = (addressLine1, city, state, zip) => {
    setMapAddress(`${addressLine1}, ${city}, ${state} ${zip}`);
    setShowMap(true);
  };

  const deleteLocation = locationId => {
    const accessToken = oktaAuth.getAccessToken();
    setLoading(true);
    fetch(`${getDomain()}tenants/UE/location/${locationId}`, {
      method: "delete",
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then(response => {
        return responseHandler(response);
      })
      .then(object => {
        if (object.status === "Success") {
          setLocationArray([
            ...locationArray.filter(
              location => location.locationId !== locationId
            )
          ]);
          setLoading(false);
          setAlertMessage("A location is successfully deleted");
        }
      })
      .catch(error => {
        setError("timeout");
        setLoading(false);
      });
  };

  const shouldShowLocation = location => {
    if (filter) {
      return (
        (location.locationId && location.locationId.indexOf(filter) >= 0) ||
        (location.city && location.city.indexOf(filter) >= 0) ||
        (location.state && location.state.indexOf(filter) >= 0) ||
        (location.postalCode && location.postalCode.indexOf(filter) >= 0) ||
        (location.airportCode && location.airportCode.indexOf(filter) >= 0)
      );
    } else {
      return true;
    }
  };

  const getLocationTableRow = location => {
    const fullAddress = (
      <Box>
        <Typography variant="body1">{location.building}</Typography>
        <Typography variant="body1">{location.addressLine1}</Typography>
        <Typography variant="body1">{location.addressLine2}</Typography>
        <Typography variant="body1">
          {location.city}, {location.state} {location.postalCode}
        </Typography>
        <Button
          color="primary"
          style={{
            textTransform: "none",
            marginLeft: "-10px"
          }}
          className={`viewMapLink-${location.locationId}`}
          onClick={() =>
            viewMapClicked(
              location.addressLine1,
              location.city,
              location.state,
              location.postalCode
            )
          }
          id="viewMapButton"
        >
          View Map
        </Button>
      </Box>
    );
    const hoursOfOperation =
      location.hoursOfOperation.length === 0 ? (
        <Typography className={classes.item} variant="body1">
          N/A
        </Typography>
      ) : (
        //  TODO FIX HOW WE ARE GETTING THESE ROWS
        <CustomTable
          headerVals={["Days", "From", "To"]}
          rows={getRowsForCustomTable(
            getTableArrayDaysOfWeekWithTime(
              getDaysOfWeekWithTimeFormatted(location.hoursOfOperation)
            )
          )}
        />
      );
    const action = (
      <Box
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          paddingTop: "20px"
        }}
      >
        <Button
          className={`editLocation-${location.locationId}`}
          component={Link}
          to={`/${location.locationId}`}
          style={{ minWidth: 0 }}
        >
          <i className="fas fa-pencil-alt fa-lg" />
        </Button>
        {location.canDelete ? (
          <Button
            className={`deleteLocation-${location.locationId}`}
            onClick={e => deleteLocation(location.locationId)}
            style={{ minWidth: 0 }}
          >
            <i className="fas fa-trash-alt fa-lg" />
          </Button>
        ) : null}
      </Box>
    );

    const locationNameAndId = (
      <Box>
        <Typography className={classes.item} variant="body1">
          {location.locationName}
        </Typography>
        <Typography className={classes.item} variant="body1">
          Location Id: {location.locationId}
        </Typography>
      </Box>
    );

    return [
      locationNameAndId,
      fullAddress,
      hoursOfOperation,
      location.description,
      action
    ];
  };

  return (
    <Box className="box-margin">
      <CustomDialog
        open={loading}
        header={"Loading....."}
        showCloseX={false}
        content={
          <CircularProgress size={36} className={classes.buttonProgress} />
        }
      />
      <SubHeader pageTitle="Location Home" tenantName="UE" />
      {loading ? null : (
        <Box>
          {error ? (
            <Box
              display="flex"
              justifyContent="center"
              style={{
                position: "absolute",
                top: "50%",
                left: "40%",
                fontWeight: "bold",
                fontSize: "20px"
              }}
            >
              {error === "timeout"
                ? "Cannot contact Server. Please try again later."
                : error === "404"
                ? "Requested <some resource> not found."
                : error === "4xx"
                ? "Bad Request."
                : error === "5xx"
                ? "Server Error."
                : error === "non-200"
                ? ""
                : ""}
            </Box>
          ) : (
            <Box>
              <CustomAlert
                alertOpen={!alert}
                setAlertOpen={() => (alert = null)}
                alertMessage={alert}
              />
              <GoogleMapDialog
                showMap={showMap}
                setShowMap={setShowMap}
                address={mapAddress}
              />
              <Divider orientation="horizontal" />
              {locationArray.length > 0 ? (
                <Box>
                  <HomeSearch
                    containerClassName={classes.homeSearchContainer}
                    searchHandler={searchHandler}
                    handleRefresh={handleRefresh}
                  />
                  <CustomAlert
                    severity="success"
                    color="success"
                    alertOpen={!!alertMessage}
                    setAlertOpen={() => setAlertMessage(null)}
                    alertMessage={alertMessage}
                  />
                  <CustomTable
                    headerVals={[
                      "Location Name",
                      "Full Address",
                      "Hours of Operation",
                      "Location Details",
                      "Action"
                    ]}
                    rows={locationArray
                      .filter(shouldShowLocation)
                      .map(getLocationTableRow)}
                    cellFlexBasisArray={["11%", "26%", "26%", "26%", "11%"]}
                    rowPerPageOptions={[25, 50, 100]}
                    rowsPerPage={25}
                  />
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  width={"100%"}
                  height={"500px"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h3" style={{ marginBottom: "15px" }}>
                    No Records Found
                  </Typography>
                  <NavButtons
                    hideLeftButton={true}
                    handleRightButtonClick={null}
                    rightButtonComponent={Link}
                    rightButtonTo={`/AddNewLocation`}
                    rightButtonId={"addLocationButton"}
                    rightButtonDisabled={false}
                    rightButtonText={"Add Location"}
                    rightButtonClassName={classes.addLocationButton}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default HomeLocation;
