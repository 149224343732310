import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableCell, Typography } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerRow: {
    width: "calc(100% - 30 px)",
    display: "flex",
    flexDirection: "row",
    margin: "0px",
    padding: "0 15px",
    justifyContent: "space-between",
  },
  headerCell: {
    padding: "0 2px 0 0",
    border: "none",
    width: "100%",
    "&:last-child": {
      paddingRight: "0",
    },
  },
  headerCellText: {
    fontWeight: "600",
  },
  contentRow: {
    width: "calc(100% - 30 px)",
    display: "flex",
    flexDirection: "row",
    margin: "0px",
    padding: "15px",
    borderRadius: "2px",
    background: "rgba(242, 242, 242, 0.35)",
    justifyContent: "space-between",
    "&:nth-child(odd)": {
      background: "rgba(242, 242, 242, 0.85)",
    },
  },
  contentCell: {
    padding: "0 2px 0 0",
    border: "none",
    width: "100%",
    "&:last-child": {
      paddingRight: "0",
    },
  },
}));

export default function CustomTable(props) {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage ? props.rowsPerPage : null
  );
  const [page, setPage] = useState(props.page ? props.page : 0);

  const flexBasisPercentage = props.headerVals
    ? (1 / props.headerVals.length) * 100 + "%"
    : "0%";

  const rowIsOnPage = (value, rowIndex) => {
    if (rowsPerPage === null) {
      return true;
    } else {
      const lowerBound = page * rowsPerPage;
      const upperBound = (page + 1) * rowsPerPage - 1;
      return rowIndex >= lowerBound && rowIndex <= upperBound;
    }
  };

  return (
    <Box>
      {props.headerVals && props.rows ? (
        <Box>
          <Table className={classes.table}>
            <TableHead className={classes.headerRow}>
              {/*
                error reported in console log: <th> cannot appear as a child of <thead>
                the <th> elements should be nested under <tr> elements, but adding <TableRow>
                causes alignment problems with the table header labels
              */}
              {/* <TableRow> */}
                {props.headerVals.map((value, headerIndex) => (
                  <TableCell
                    className={classes.headerCell}
                    style={{
                      flexBasis: props.cellFlexBasisArray
                        ? props.cellFlexBasisArray[headerIndex]
                        : flexBasisPercentage,
                    }}
                    key={"HeaderCell" + headerIndex}
                  >
                    <Typography
                      className={classes.headerCellText}
                      variant="body1"
                    >
                      {value}
                    </Typography>
                  </TableCell>
                ))}
              {/* </TableRow> */}
            </TableHead>
            <TableBody>
              {props.rows.filter(rowIsOnPage).map((row, contentRowIndex) => (
                <TableRow
                  className={classes.contentRow}
                  key={"ContentRow" + contentRowIndex}
                >
                  {row.map((value, contentCellIndex) => (
                    <TableCell
                      className={classes.contentCell}
                      style={{
                        flexBasis: props.cellFlexBasisArray
                          ? props.cellFlexBasisArray[contentCellIndex]
                          : flexBasisPercentage,
                      }}
                      key={
                        "ContentCell" + contentRowIndex + "_" + contentCellIndex
                      }
                    >
                      {typeof value === "object" ? (
                        <Box style={{ height: "100%", width: "100%" }}>
                          {value}
                        </Box>
                      ) : (
                        <Typography variant="body1">{value}</Typography>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rowsPerPage ? (
            <TablePagination
              className={`parentPagination`}
              nextIconButtonProps={{ id: "nextPage" }}
              backIconButtonProps={{ id: "previousPage" }}
              rowsPerPageOptions={props.rowsPerPageOptions}
              component="div"
              count={props.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(event, newPage) => setPage(newPage)}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              classes={{
                select: `paginationDropdown`,
                caption: `paginationText`,
              }}
              onPageChange={() => {}}
            />
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
}
