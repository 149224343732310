import { React, useState } from "react";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { getDomain } from "../Domain";
import { useOktaAuth } from "@okta/okta-react";
import { getHoursWithPosition } from "../HoursOfOperation/Util/dayOfWeek";
import { CircularProgress } from "@material-ui/core";
import NavButtons from "../components/NavButtons";
import CustomDialog from "../components/CustomDialog";
import ReviewContent from "./ReviewContent";
import { responseHandler } from "../utilities/HttpResponseHandler";
import { messages } from "../Constants/Message";

const Review = (props) => {
  let history = useHistory();
  let { locationInfo, hoursOfOperation } = props.locationObj;
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState(false);

  let isTestUser = null;
  // TODO MOVE THIS TO SOMEWHERE ELSE
  oktaAuth
    .getUser()
    .then(
      (user) =>
        (isTestUser = user.email === "paymentconfig-test-user@us.idemia.com")
    )
    .catch((error) => {
      console.warn("No user logged in");
      isTestUser = false;
    });

  const createLocation = () => {
    const accessToken = oktaAuth.getAccessToken();
    setLoading(true);

    while (isTestUser === null) {
      console.info("Waiting for Okta to inform us on test user.");
    }

    let urlString = "";
    let method = "post";
    if (props.mode === "addNewLocation") {
      urlString = `${getDomain()}tenants/UE/location/`;
    } else if (props.mode === "addFutureLocation") {
      urlString = `${getDomain()}tenants/UE/location/${
        locationInfo.locationId
      }`;
    } else if (props.mode === "editFutureLocation") {
      urlString = `${getDomain()}tenants/UE/location/${
        locationInfo.locationId
      }/${encodeURIComponent(props.locationObj.oldEffectiveDate)}`;
      method = "put";
    }

    fetch(urlString, {
      method,
      body: JSON.stringify({
        locationId: locationInfo.locationId,
        name: locationInfo.locationName,
        bssrid:
          locationInfo.bssrid === undefined || locationInfo.bssrid === ""
            ? null
            : locationInfo.bssrid,
        description:
          locationInfo.description === undefined ||
          locationInfo.description === ""
            ? null
            : locationInfo.description,
        locationType: locationInfo.locationType,
        addressLine1: locationInfo.addressLine1,
        addressLine2:
          locationInfo.addressLine2 === undefined ||
          locationInfo.addressLine2 === ""
            ? null
            : locationInfo.addressLine2,
        addressCity: locationInfo.city,
        addressStateProv:
          locationInfo.state === undefined || locationInfo.state === ""
            ? null
            : locationInfo.state.slice(0, 3),
        building:
          locationInfo.building === undefined || locationInfo.building === ""
            ? null
            : locationInfo.building,
        addressPostalCode:
          locationInfo.postalCode === undefined ||
          locationInfo.postalCode === ""
            ? null
            : locationInfo.postalCode,
        specialLocationAccessCode:
          locationInfo.specialLocationAccessCode === undefined ||
          locationInfo.specialLocationAccessCode === "" ||
          locationInfo.specialLocationAccessCode === null
            ? null
            : "SP-" + locationInfo.specialLocationAccessCode.replace("SP-", ""),
        airportCode:
          locationInfo.airportCode === undefined ||
          locationInfo.airportCode === ""
            ? null
            : locationInfo.airportCode,
        hostName:
          locationInfo.partnerType === "Host" ? locationInfo.hostName : null,
        latitude: parseFloat(locationInfo.latitude),
        longitude: parseFloat(locationInfo.longitude),
        hoursOfOperation: getHoursWithPosition(hoursOfOperation),
        timezone: locationInfo.timezone,
        partnerName: locationInfo.partnerName,
        partnerType: locationInfo.partnerType,
        partnerLocationId:
          locationInfo.partnerLocationId === undefined ||
          locationInfo.partnerLocationId === ""
            ? null
            : locationInfo.partnerLocationId,
        specialLocationFee:
          locationInfo.specialLocationFee === undefined
            ? null
            : parseFloat(locationInfo.specialLocationFee),
        effectiveDate:
          locationInfo.effectiveDate === "" ? null : locationInfo.effectiveDate,
        appointments: locationInfo.appointments,
        sameDayAppointments: locationInfo.sameDayAppointments,
        oneVisitEligible: locationInfo.oneVisitEligible,
        walkins: locationInfo.walkins,
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        USE_TAX_JAR_MOCK: isTestUser,
      },
    })
      .then((response) => {
        return responseHandler(response);
      })
      .then((object) => {
        history.push({
          pathname: `/${locationInfo.locationId}`,
          state: {
            fromNotifications: "success",
            locationId: locationInfo.locationId,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        if (error.message === "4xx") {
          props.setAlertMessage(messages.duplicatedEcr);
        } else {
          props.setAlertMessage(messages.serverError);
        }
        setLoading(false);
      });
  };

  return (
    <Box>
      <ReviewContent
        hoursOfOperation={hoursOfOperation}
        locationInfo={locationInfo}
      />
      <NavButtons
        handleLeftButtonClick={props.handleBack}
        leftButtonId={"backButton"}
        leftButtonDisabled={false}
        leftButtonText={"Back"}
        handleRightButtonClick={createLocation}
        rightButtonId={"updateButton"}
        rightButtonDisabled={false}
        rightButtonText={
          props.mode === "editFutureLocation" ? "Update" : "Create"
        }
      />
      <CustomDialog
        open={loading}
        header={"Saving....."}
        showCloseX={false}
        content={<CircularProgress size={36} />}
      />
    </Box>
  );
};

export default Review;
