import React, { useEffect, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import * as OktaSignIn from "@okta/okta-signin-widget";
import { makeStyles } from "@material-ui/core/styles";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import getOktaConfig from "./OktaConfig";
import logo from "./img/idemia-logo.png";

const useStyles = makeStyles((theme) => ({
  oktaLoginWidget: {
    marginTop: "10rem",
  },
}));

const Login = () => {
  const { oktaAuth } = useOktaAuth();
  const widgetRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const { issuer, clientId, redirectUri, scopes } = getOktaConfig();
    const widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: issuer.split("/oauth2")[0],
      clientId,
      redirectUri,
      logo,
      i18n: {
        en: {
          "primaryauth.title": "Sign in to Location Admin Portal",
        },
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes,
      },
      useInteractionCodeFlow: false, // Set to true, if your org is OIE enabled
    });

    widget.renderEl(
      { el: widgetRef.current },
      (res) => {
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (err) => {
        throw err;
      }
    );

    return () => widget.remove();
  }, [oktaAuth]);

  return (
    <div>
      <div ref={widgetRef} className={classes.oktaLoginWidget} />
    </div>
  );
};
export default Login;
