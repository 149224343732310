import React, { useEffect, useRef } from "react";
import { OktaAuth } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import CustomLoginComponent from "./Login";
import getOktaConfig from "./OktaConfig";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import HomeLocation from "./HomeLocation";
import AddNewLocationHome from "./AddNewLocationHome";
import EditLocation from "./EditLocationHome";
import AddFutureLocation from "./AddFutureLocationHome";
import EditFutureLocation from "./EditFutureLocationHome";
import Navbar from "./Navbar";
import UserSessionTimeoutService from "./UserAuthentication/UserSessionTimeoutService";
import "./fonts/fonts.css";
import { useIsModule } from "./context";

const theme = createTheme({
  typography: {
    fontFamily: '"IDEMIA", "Roboto", "Helvetica", "Arial", sans-serif',
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "12px",
    letterSpacing: "0.5px",
    body1: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "normal",
    },
    h1: {
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "19px",
      letterSpacing: "0.5px",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "19px",
      letterSpacing: "0.5px",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "16px",
      letterSpacing: "0.5px",
    },
  },
  palette: {
    primary: {
      main: "#430099",
    },
    secondary: {
      main: "#646464",
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        paddingBottom: "14px",
      },
    },
    MuiFormControlLabel: {
      label: {
        lineHeight: 1,
        paddingTop: "6px",
      },
    },
    MuiButton: {
      root: {
        padding: "12px 12px 10px",
        height: "40px",
      },
      label: {
        fontSize: "14px",
        lineHeight: 1.3,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "10px",
        lineHeight: 1,
        position: "absolute",
        bottom: "2px",
      },
    },
    MuiAlert: {
      message: {
        lineHeight: "1",
        padding: "16px 0 6px",
      },
      icon: {
        padding: "10px 0 0",
        margin: "0 12px 0 0",
      },
    },
  },
});

// reset backgroundColor and color for consistency
// between standalone and module mode styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    color: '#000000'
  },
  header: {
    justifyContent: "space-between",
  },
  main: {
    margin: "0 15px",
  },
  flexGrow: {
    flexGrow: 1
  }
}));

const oktaAuth = new OktaAuth(getOktaConfig());

const App = () => {
  const history = useHistory();
  const isModule = useIsModule();

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push("/login");
  };

  const classes = useStyles();

  const location = useLocation();

  const ustsRef = useRef();

  useEffect(() => {
    if (ustsRef.current) {
      ustsRef.current.pageChanged(location);
    }
  }, [location]);

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler}>
      <ThemeProvider theme={theme}>
        <Box className={classes.root} id="manageLocation">
          {!isModule && <UserSessionTimeoutService oktaAuth={oktaAuth} ref={ustsRef} />}
          {!isModule && <Navbar classes={classes} />}
          <main className={classes.main}>
            <Switch>
              <Route path="/login" component={CustomLoginComponent} />
              <Route path="/login/callback" component={LoginCallback} />
              <SecureRoute
                exact
                path="/AddNewLocation"
                component={AddNewLocationHome}
              />
              <SecureRoute
                exact
                path="/AddFutureLocation/:locationId"
                component={AddFutureLocation}
              />
              <SecureRoute
                exact
                path="/EditFutureLocation/:locationId"
                component={EditFutureLocation}
              />
              <SecureRoute exact path="/:locationId" component={EditLocation} />
              <SecureRoute exact path="/" component={HomeLocation} />
            </Switch>
          </main>
        </Box>
      </ThemeProvider>
    </Security>
  );
};

export default App;
