import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  main: {
    fontFamily: "IDEMIA",
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "14px",
    letterSpacing: ".5px",
    display: "flex",
    flexGrow: "1",
    flexShrink: "0",
    margin: "3px",
  },
  subText: {
    color: "#676767",
    fontWeight: "normal",
    fontSize: "10px",
  },
  enableAll: {
    flexBasis: "30%",
    marginLeft: "9px",
    boxSizing: "border-box",
  },
  status: {
    flexBasis: "10%",
  },
  pubToWeb: {
    flexBasis: "20%",
    marginLeft: "9px",
  },
  outOfState: {
    flexBasis: "20%",
    marginLeft: "9px",
  },
  serviceFee: {
    flexBasis: "20%",
  },
}));

export default function ProgramHeaderRow(props) {
  const classes = useStyles();

  return (
    <Box style={props.style} className={classes.main}>
      <Box style={{ width: "74px", flexGrow: 0, flexShrink: 0 }} />
      <Box className={classes.enableAll}>
        Enable All Services
        <br />
        <span className={classes.subText}>
          Selecting a program will enable all corresponding services.
        </span>
      </Box>
      <Box className={classes.status}>Program Status</Box>
      <Box className={classes.pubToWeb}>Publish to Web - All Services</Box>
      <Box className={classes.outOfState}>Out of State - All Services</Box>
      <Box className={classes.serviceFee}>
        Service Fee Adjustment - All Services
      </Box>
    </Box>
  );
}
