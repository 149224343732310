import { masterWeek } from "../../HourConstant";
import { React } from "react";

export class DayOfWeek {
  sunday = false;
  monday = true;
  tuesday = false;
  wednesday = true;
  thursday = false;
  friday = true;
  saturday = false;
  startTime = "08:00Z";
  endTime = "17:00Z";

  getDays() {
    return masterWeek;
  }

  toKey(day, index) {
    return day + index + this[day];
  }
}

export const dayOfWeekTemplate = () => {
  return new DayOfWeek();
};

export function areActiveDaysTheSame(tempDaysOfWeek, checkDaysOfWeek) {
  let foundDays = true;
  for (const i in masterWeek) {
    if (tempDaysOfWeek[masterWeek[i]] !== checkDaysOfWeek[masterWeek[i]]) {
      foundDays = false;
      break;
    }
  }
  return foundDays;
}

export function getUniqueDaysOfWeekSegments(daysOfWeekFromHours) {
  const uniqueDaysOfWeekFromHours = [];

  for (let i = 0; i < daysOfWeekFromHours.length; ++i) {
    const tempDaysOfWeek = daysOfWeekFromHours[i];
    if (uniqueDaysOfWeekFromHours.length === 0) {
      uniqueDaysOfWeekFromHours.push(tempDaysOfWeek);
    } else {
      let found = false;
      for (let j = 0; j < uniqueDaysOfWeekFromHours.length; ++j) {
        const checkDaysOfWeek = uniqueDaysOfWeekFromHours[j];
        found = found || areActiveDaysTheSame(tempDaysOfWeek, checkDaysOfWeek);
      }
      if (!found) {
        uniqueDaysOfWeekFromHours.push(tempDaysOfWeek);
      }
    }
  }

  return uniqueDaysOfWeekFromHours;
}

export function formatTimeAmPM(time) {
  let formattedTime = "";

  const hours = parseInt(time.split(":")[0]);

  if (hours === 0) {
    formattedTime = "12:" + time.split(":")[1] + " AM";
  } else if (hours < 12) {
    formattedTime = hours + ":" + time.split(":")[1] + " AM";
  } else if (hours === 12) {
    formattedTime = hours + ":" + time.split(":")[1] + " PM";
  } else {
    const calcHours = hours - 12;
    formattedTime = calcHours + ":" + time.split(":")[1] + " PM";
  }

  return formattedTime;
}

export function getFormattedDaysOfWeekWithTime(
  uniqueDaysOfWeekFromHours,
  hours
) {
  const daysOfWeekWithTime = [];
  for (let i = 0; i < uniqueDaysOfWeekFromHours.length; ++i) {
    let daysOfWeek = uniqueDaysOfWeekFromHours[i];
    const filteredDaysOfWeek = hours.filter((value) =>
      areActiveDaysTheSame(value, daysOfWeek)
    );

    if (filteredDaysOfWeek.length > 0) {
      const increment = {};
      increment["formattedDaysOfWeek"] = getFormattedDaysOfWeek(daysOfWeek);
      const formattedTimes = [];
      for (let j = 0; j < filteredDaysOfWeek.length; ++j) {
        const filtered = filteredDaysOfWeek[j];
        formattedTimes.push(
          formatTimeAmPM(filtered.startTime.replace("Z", "")) +
            " - " +
            formatTimeAmPM(filtered.endTime.replace("Z", ""))
        );
      }
      increment["formattedTimes"] = formattedTimes;
      daysOfWeekWithTime.push(increment);
    }
  }
  return daysOfWeekWithTime;
}

export function getFormattedDaysOfWeek(daysOfWeek) {
  let output = "";
  const activeDays = masterWeek.filter((x) => daysOfWeek[x]);
  let actualIndexes = activeDays.map((el) =>
    masterWeek.findIndex((d) => d.toLowerCase() === el.toLowerCase())
  );
  if (actualIndexes.length === 1) {
    output =
      activeDays[0].slice(0, 1).toUpperCase() +
      activeDays[0].slice(1).toLowerCase();
  } else if (!isModularConsecutive(actualIndexes, 7)) {
    for (let i = 0; i < activeDays.length; ++i) {
      if (output === "") {
        output +=
          activeDays[i].slice(0, 1).toUpperCase() +
          activeDays[i].slice(1).toLowerCase();
      } else {
        output +=
          ", " +
          activeDays[i].slice(0, 1).toUpperCase() +
          activeDays[i].slice(1).toLowerCase();
      }
    }
  } else {
    output =
      activeDays[0].slice(0, 1).toUpperCase() +
      activeDays[0].slice(1).toLowerCase() +
      " - " +
      activeDays[activeDays.length - 1].slice(0, 1).toUpperCase() +
      activeDays[activeDays.length - 1].slice(1).toLowerCase();
  }

  return output;
}

export function isModularConsecutive(a, modulus) {
  const reduceResult = a.reduce((prev, current, index) => {
    return index === 0 || current === (prev + 1) % modulus ? current : NaN;
  });
  return !isNaN(reduceResult);
}

export function getDaysOfWeekWithTimeFormatted(hours) {
  const uniqueDaysOfWeekFromHours = getUniqueDaysOfWeekSegments(hours);
  return getFormattedDaysOfWeekWithTime(uniqueDaysOfWeekFromHours, hours);
}

export function getHoursWithPosition(hours) {
  let tempHours = hours;
  for (let i = 0; i < hours.length; ++i) {
    tempHours[i].position = i + 1;
  }

  return tempHours;
}

export function getHoursClassObject(hours) {
  const tempHours = hours.sort((hour) => hour.position);
  const output = [];

  for (const index in tempHours) {
    const tempData = tempHours[index];
    const hoursObj = new DayOfWeek();
    hoursObj.sunday = tempData.sunday;
    hoursObj.monday = tempData.monday;
    hoursObj.tuesday = tempData.tuesday;
    hoursObj.wednesday = tempData.wednesday;
    hoursObj.thursday = tempData.thursday;
    hoursObj.friday = tempData.friday;
    hoursObj.saturday = tempData.saturday;
    hoursObj.startTime = tempData.startTime;
    hoursObj.endTime = tempData.endTime;

    output.push(hoursObj);
  }

  return output;
}

export const getTableArrayDaysOfWeekWithTime = (daysOfWeekWithTime) => {
  const output = [];

  for (let index in daysOfWeekWithTime) {
    const tempArray = [];
    let startTimes = daysOfWeekWithTime[index].formattedTimes.map(
      (time, index) => time.substring(0, time.indexOf(" - "))
    );
    let endTimes = daysOfWeekWithTime[index].formattedTimes.map((time, index) =>
      time.substring(time.indexOf(" - ") + 3)
    );

    tempArray.push(daysOfWeekWithTime[index].formattedDaysOfWeek);
    tempArray.push(startTimes);
    tempArray.push(endTimes);

    output.push(tempArray);
  }

  return output;
};

export const getRowsForCustomTable = (tableArrayDaysOfWeekWithTime) => {
  const output = [];

  for (let rowIndex in tableArrayDaysOfWeekWithTime) {
    const row = tableArrayDaysOfWeekWithTime[rowIndex];
    for (let timeIndex in row[1]) {
      output.push([row[0], row[1][timeIndex], row[2][timeIndex]]);
    }
  }

  return output;
};
