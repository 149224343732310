import Search from "../components/Search";

export default function GlobalSearch(props) {
  return (
    <Search
      valueChanged={props.valueChanged}
      clearTextOnValueChanged={false}
      searchOptions={props.programsAvailableAndServicesEnabled}
      style={props.style}
      searchItemsHeader={"PROGRAMS"}
      searchNoItemsHeader={"NO PROGRAMS OR SERVICES FOUND"}
      zIndex={50}
      optionsValue={"name"}
    />
  );
}
