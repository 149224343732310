let WarningError = {
  locationId: (locationId) => locationId && "Invalid ECR",
  locationName: (locationName) => locationName && "Invalid Location Name",
  bssrid: (bssrid) => bssrid && "Invalid BSSR ID",
  effectiveDate: (effectiveDate) => effectiveDate && "Invalid Effective Date",
  building: (building) => building && "Invalid Building Name",
  addressLine1: (addressLine1) => addressLine1 && "Invalid Address 1",
  addressLine2: (addressLine2) => addressLine2 && "Invalid Address 2",
  city: (city) => city && "Invalid City",
  state: (state) => state && "Invalid State",
  postalCode: (postalCode) => postalCode && "Invalid Postal Code",
  longitude: (longitude) => longitude && "Invalid Longitude",
  latitude: (latitude) => latitude && "Invalid Latitude",
  description: (description) => description && "Invalid Description",
  locationType: (locationType) => locationType && "Invalid Location Type",
  partnerType: (partnerType) => partnerType && "Invalid Partner Type",
  hostName: (hostname) => hostname && "Invalid Host Name",
  partnerName: (partnerName) => partnerName && "Invalid Partner Name",
  partnerLocationId: (partnerLocationId) =>
    partnerLocationId && "Invalid Partner Location Id",
  airportCode: (airportCode) => airportCode && "Invalid Airport Code",
  timezone: (timezone) => timezone && "Invalid Timezone",
  specialLocationFee: (specialLocationFee) =>
    specialLocationFee && "Invalid Special Location Fee",
  specialLocationAccessCode: (specialLocationAccessCode) =>
    specialLocationAccessCode && "Invalid Access Code",
  closureReason: (closureReason) => closureReason && "Invalid Closure Reason",
};

export default WarningError;
