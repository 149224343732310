export const messages = {
  serverError:
    "We cannot connect to the server at this time. Please try again later",
  duplicatedEcr:
    "Cannot add location because a location with that ecr already exists.",
  oneDay: "At least one day of the week must be checked.",
  endTimeAfterEndTime: "The end time must be after the start time.",
  smartyStreetError:
    "Could not find coordinates from provided address or cannot connect to the server. Please update the address or enter the coordinates manually.",
};
