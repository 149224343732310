import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import StatusLabel from "../components/StatusLabel";
import CustomCheckbox from "../components/CustomCheckbox";
import FlagTextInput from "../components/FlagTextInput";
import CustomDialog from "../components/CustomDialog";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  main: {
    fontFamily: "IDEMIA",
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "14px",
    letterSpacing: ".5px",
    display: "flex",
    flexGrow: "1",
    flexShrink: "0",
    height: "53px",
    borderRadius: "4px"
  },
  even: {
    background: "rgba(242, 242, 242, 0.85)"
  },
  odd: {
    background: "rgba(242, 242, 242, 0.35)"
  },
  child: {
    margin: "20px 0"
  },
  withCheckBox: {
    display: "flex",
    alignItems: "center"
  },
  withCheckBoxText: {
    marginTop: "4px"
  },
  enableAll: {
    flexBasis: "30%"
  },
  status: {
    flexBasis: "10%"
  },
  pubToWeb: {
    flexBasis: "20%"
  },
  outOfState: {
    flexBasis: "20%"
  },
  serviceFee: {
    flexBasis: "20%",
    margin: "8px 0"
  },
  disabledText: {
    color: "rgba(0,0,0,.26)"
  }
}));

export default function ProgramRowMainContent(props) {
  const classes = useStyles();

  const getProgramStatusIndicator = () => {
    let check = props.availableServices.filter(
      service =>
        !props.servicesConfig.find(
          modifiedService => modifiedService.name === service.name
        )
    );

    if (props.programConfig) {
      return <StatusLabel status={"ENABLED"} />;
    } else if (props.availableServices.length !== check.length) {
      return <StatusLabel status={"EDITED"} />;
    } else {
      return <StatusLabel />;
    }
  };

  return (
    <Box
      style={props.style}
      className={`${classes.main} ${
        props.index % 2 === 0 ? classes.even : classes.odd
      }`}
    >
      <Box
        style={{
          width: "74px",
          textAlign: "center",
          flexShrink: 0,
          flexGrow: 0
        }}
        className={classes.child}
        onClick={props.onCollapseChanged}
      >
        {props.collapsed ? (
          <i className="fas fa-plus fa-lg" />
        ) : (
          <i className="fas fa-minus fa-lg" />
        )}
      </Box>
      <Box
        className={`${classes.child} ${classes.enableAll} ${
          classes.withCheckBox
        }`}
      >
        <CustomCheckbox
          checked={props.programConfig ? props.programConfig.active : false}
          onChange={props.activeChanged}
        />

        <span className={classes.withCheckBoxText}>{props.program.name}</span>
      </Box>
      <Box className={`${classes.child} ${classes.status}`}>
        {getProgramStatusIndicator()}
      </Box>
      <Box
        className={`${classes.child} ${classes.pubToWeb} ${
          classes.withCheckBox
        }`}
      >
        <CustomCheckbox
          disabled={!props.programConfig || !props.programConfig.active}
          checked={
            props.programConfig ? props.programConfig.publishToWeb : false
          }
          onChange={props.publishToWebChanged}
        />
        <span
          className={`${classes.withCheckBoxText} ${
            !props.programConfig || !props.programConfig.active
              ? classes.disabledText
              : undefined
          }`}
        >
          Publish To Web
        </span>
      </Box>
      <Box
        className={`${classes.child} ${classes.outOfState} ${
          classes.withCheckBox
        }`}
      >
        <CustomCheckbox
          disabled={!props.programConfig || !props.programConfig.active}
          checked={
            props.programConfig ? props.programConfig.outOfStateFees : false
          }
          onChange={props.outOfStateFeesChanged}
        />
        <span
          className={`${classes.withCheckBoxText} ${
            !props.programConfig || !props.programConfig.active
              ? classes.disabledText
              : undefined
          }`}
        >
          Out of State Fees
        </span>
      </Box>
      <Box className={`${classes.serviceFee}`}>
        <FlagTextInput
          valueChange={props.serviceFeeAdjustmentChanged}
          value={
            props.programConfig && props.programConfig.active
              ? props.programConfig.serviceFeeAdjustment
              : 0
          }
          disabled={!props.programConfig || !props.programConfig.active}
          default={0}
        />
      </Box>
    </Box>
  );
}
