import { stateArray } from "./stateArray";
import { closureReasonArray } from "./Constants/ClosureReason";
import moment from "moment-timezone";

let FormValidation = {
  locationId: (input) => /^[0-9]+$/.test(input),
  locationName: (input) =>
    !!input && doesNotHaveStartingOrEndingWhitespace(input),
  bssrid: (input) => doesNotHaveStartingOrEndingWhitespace(input),
  effectiveDate: (effectiveDateInput, timezoneInput, mode) =>
    isEffectiveDateValid(effectiveDateInput, timezoneInput, mode),
  building: (input) => !!input && doesNotHaveStartingOrEndingWhitespace(input),
  addressLine1: (input) =>
    !!input && doesNotHaveStartingOrEndingWhitespace(input),
  addressLine2: (input) => doesNotHaveStartingOrEndingWhitespace(input),
  city: (input) => !!input && doesNotHaveStartingOrEndingWhitespace(input),
  state: (input) => !!stateArray.find((val) => val.state === input),
  postalCode: (input) => /^\d{5}(-\d{4})?$/.test(input),
  longitude: (input) =>
    !!input &&
    doesNotHaveStartingOrEndingWhitespace(input) &&
    isFinite(input) &&
    Math.abs(input) <= 180,
  latitude: (input) =>
    !!input &&
    doesNotHaveStartingOrEndingWhitespace(input) &&
    isFinite(input) &&
    Math.abs(input) <= 90,
  description: (input) => doesNotHaveStartingOrEndingWhitespace(input),
  locationType: (input) =>
    !!input && doesNotHaveStartingOrEndingWhitespace(input),
  partnerType: (input) =>
    !!input && doesNotHaveStartingOrEndingWhitespace(input),
  hostName: (partnerType, hostName) =>
    partnerType !== "Host" ||
    (!!hostName && doesNotHaveStartingOrEndingWhitespace(hostName)),
  partnerName: (input) =>
    !!input && doesNotHaveStartingOrEndingWhitespace(input),
  partnerLocationId: (input) => doesNotHaveStartingOrEndingWhitespace(input),
  airportCode: (input) => doesNotHaveStartingOrEndingWhitespace(input),
  timezone: (input) => !!input && doesNotHaveStartingOrEndingWhitespace(input),
  appointments: (input) => input === true || input === false,
  sameDayAppointments: (input) => input === true || input === false,
  walkins: (input) => input === true || input === false,
  oneVisitEligible: (input) => input === true || input === false,
  specialLocationFee: (input) =>
    input === null ||
    input === undefined ||
    /^\d{0,19}(\.\d{0,2})?$/.test(input),
  specialLocationAccessCode: (input) =>
    doesNotHaveStartingOrEndingWhitespace(input),
  closureReason: (input) =>
    !!closureReasonArray.find((val) => val.closureReason === input),
};

function doesNotHaveStartingOrEndingWhitespace(value) {
  return /^(?!\s+)\S*(.*\S+)?(?!\s+)$/.test(value);
}

function isEffectiveDateValid(effectiveDateInput, timezoneInput, mode) {
  let currentDateInTimezone = moment.tz(timezoneInput).format("YYYY-MM-DD");
  if (mode === "addLocation") {
    return (
      effectiveDateInput >= currentDateInTimezone &&
      effectiveDateInput.length === 10
    );
  } else {
    return (
      effectiveDateInput > currentDateInTimezone &&
      effectiveDateInput.length === 10
    );
  }
}

export default FormValidation;
