import { React, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EditFutureHoursOfLocation from "./EditFutureHoursOfLocation";
import EditFutureProgramAvailability from "./EditFutureLocationAvailability";
import Review from "./Review";
import { useLocation } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { getHoursClassObject } from "./HoursOfOperation/Util/dayOfWeek";
import LocationInfo from "./LocationInfo";
import FormValidation from "./FormValidation";
import SubHeader from "./components/SubHeader";
import CustomAlert from "./components/CustomAlert";
import { getInfoFromLocationVersion } from "./utilities/LocationUtil";
import { getAvailableProgramsAndService } from "./utilities/ProgramsAndServices";

function getSteps() {
  return [
    "Location Information",
    "Hours of Operation",
    "Program Availability",
    "Add Future Location",
  ];
}

function isInfoFormValid(checkClean, locationInfoObj, dirtyValues) {
  return Object.keys(locationInfoObj).reduce((prev, next) => {
    if (
      [
        "salesTaxRate",
        "locationId",
        "locationName",
        "bssrid",
        "description",
      ].find((val) => val === next)
    ) {
      return prev;
    }

    let hasError;
    if (next === "effectiveDate") {
      hasError = !FormValidation.effectiveDate(
        locationInfoObj.effectiveDate,
        locationInfoObj.timezone,
        "editLocation"
      );
    } else if (next === "hostName") {
      hasError = !FormValidation.hostName(
        locationInfoObj.partnerType,
        locationInfoObj.hostName
      );
    } else {
      hasError = !FormValidation[next](locationInfoObj[next]);
    }

    if (checkClean) {
      return prev && !hasError;
    } else {
      const isDirty = dirtyValues[next];
      if (isDirty) {
        return prev && !hasError;
      } else {
        return prev && true;
      }
    }
  }, true);
}

function getStepContent(
  step,
  locationObj = null,
  childHandleLocationInfoNext,
  childHandleHoursNext,
  childHandleProgramNext,
  childHandleHoursBack,
  childHandleProgramBack,
  childHandleReviewBack,
  setAlertMessage
) {
  switch (step) {
    case 0:
      return (
        <LocationInfo
          locationObj={locationObj.locationInfo}
          handleNext={childHandleLocationInfoNext}
          isFormValid={isInfoFormValid}
          locationIdEditable={false}
          locationNameEditable={false}
          showSalesTax={true}
          bssridEditable={false}
          descriptionEditable={false}
          mode="editLocation"
          setAlertMessage={setAlertMessage}
        />
      );
    case 1:
      return (
        <EditFutureHoursOfLocation
          handleNext={childHandleHoursNext}
          handleBack={childHandleHoursBack}
          hours={
            locationObj.hoursOfOperation
              ? getHoursClassObject(locationObj.hoursOfOperation)
              : []
          }
        />
      );
    case 2:
      return (
        <EditFutureProgramAvailability
        handleNext={childHandleProgramNext}
        handleBack={childHandleProgramBack}
        availableProgramsAndServices={availableProgramsAndServices}
        programsAndServicesConfig={
          locationObj.programsAndServicesConfig
            ? locationObj.programsAndServicesConfig
            : {}
        }
        />
      );
    case 3:
      return (
        <Review
          locationObj={locationObj}
          handleBack={childHandleReviewBack}
          mode="editFutureLocation"
          setAlertMessage={setAlertMessage}
        />
      );
    default:
      return "Unknown step";
  }
}

const availableProgramsAndServices = getAvailableProgramsAndService().map(
  obj => {
    let services = obj.services.map(service => {
      return {
        name: service.codeId + " - " + service.name,
        codeId: service.codeId
      };
    });

    return { name: obj.name, services };
  }
);

const EditFutureLocation = () => {
  const steps = getSteps();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [locationObj, setLocationObj] = useState({
    oldEffectiveDate: location.state.common.effectiveDate,
    locationInfo: getInfoFromLocationVersion(location.state.common),
    hoursOfOperation: location.state.common.hoursOfOperation,
    programsAndServicesConfig: {},
  });

  const [alertMessage, setAlertMessage] = useState("");

  const handleNext = (obj) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setLocationObj(obj);
  };

  const handleLocationInfoNext = (locationInfo) => {
    const tempData = locationObj;
    tempData.locationInfo = locationInfo;
    handleNext(tempData);
  };

  const handleHoursNext = (hoursObj) => {
    const tempData = locationObj;
    tempData.hoursOfOperation = hoursObj;
    handleNext(tempData);
  };

  const handleProgramNext = (programsAndServicesConfig) => {
    const tempData = locationObj;
    tempData.programsAndServicesConfig = programsAndServicesConfig;
    handleNext(tempData);
  };

  const handleHoursBack = (hoursObj) => {
    const tempData = locationObj;
    tempData.hoursOfOperation = hoursObj;
    handleBack(tempData);
  };

  const handleProgramBack = (programsAndServicesConfig) => {
    const tempData = locationObj;
    tempData.programsAndServicesConfig = programsAndServicesConfig;
    handleBack(tempData);
  };

  const handleReviewBack = () => {
    handleBack(locationObj);
  };

  const handleBack = (obj) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setLocationObj(obj);
  };

  return (
    <Box className="box-margin">
      <SubHeader pageTitle="Edit Location" tenantName="UE" />
      <Divider orientation="horizontal" />
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <CustomAlert
        alertOpen={!!alertMessage}
        setAlertOpen={() => setAlertMessage(null)}
        alertMessage={alertMessage}
      />
      <Box>
        <Typography>
          {getStepContent(
            activeStep,
            locationObj,
            handleLocationInfoNext,
            handleHoursNext,
            handleProgramNext,
            handleHoursBack,
            handleProgramBack,
            handleReviewBack,
            setAlertMessage
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default EditFutureLocation;
