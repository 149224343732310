import Checkbox from "@material-ui/core/Checkbox";
import CheckedIcon from "../svg/CheckedIcon";
import UncheckedIcon from "../svg/UncheckedIcon";
import IndeterminateIcon from "../svg/IndeterminateIcon";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  disabled: {
    "& svg": {
      opacity: ".26",
    },
  },
}));

export default function CustomCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      {...props}
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
      classes={{ disabled: classes.disabled }}
    />
  );
}
