import { React, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Box from "@material-ui/core/Box";
import HoursOfLocation from "./AddNewLocationHours";
import ProgramAvailability from "./AddNewLocationAvailability";
import Review from "./Review";
import Divider from "@material-ui/core/Divider";
import LocationInfo from "./LocationInfo";
import FormValidation from "./FormValidation";
import moment from "moment";
import SubHeader from "./components/SubHeader";
import CustomAlert from "./components/CustomAlert";
import { getAvailableProgramsAndService } from "./utilities/ProgramsAndServices";

function getSteps() {
  return [
    "Location Information",
    "Hours of Operation",
    "Program Availability",
    "Create Location"
  ];
}

function isInfoFormValid(checkClean, locationInfoObj, dirtyValues) {
  return Object.keys(locationInfoObj).reduce((prev, next) => {
    let hasError;

    if (next === "effectiveDate") {
      hasError = !FormValidation.effectiveDate(
        locationInfoObj.effectiveDate,
        locationInfoObj.timezone,
        "addLocation"
      );
    } else if (next === "hostName") {
      hasError = !FormValidation.hostName(
        locationInfoObj.partnerType,
        locationInfoObj.hostName
      );
    } else {
      hasError = !FormValidation[next](locationInfoObj[next]);
    }

    if (checkClean) {
      return prev && !hasError;
    } else {
      const isDirty = dirtyValues[next];
      if (isDirty) {
        return prev && !hasError;
      } else {
        return prev && true;
      }
    }
  }, true);
}

function getStepContent(
  step,
  locationObj = null,
  childHandleLocationInfoNext,
  childHandleHoursNext,
  childHandleProgramNext,
  childHandleHoursBack,
  childHandleProgramBack,
  childHandleReviewBack,
  setAlertMessage
) {
  switch (step) {
    case 0:
      return (
        <LocationInfo
          locationObj={locationObj.locationInfo}
          handleNext={childHandleLocationInfoNext}
          isFormValid={isInfoFormValid}
          locationIdEditable={true}
          locationNameEditable={true}
          showSalesTax={false}
          bssridEditable={true}
          descriptionEditable={true}
          mode="addLocation"
          setAlertMessage={setAlertMessage}
        />
      );
    case 1:
      return (
        <HoursOfLocation
          handleNext={childHandleHoursNext}
          handleBack={childHandleHoursBack}
          hours={
            locationObj.hoursOfOperation ? locationObj.hoursOfOperation : []
          }
        />
      );
    case 2:
      return (
        <ProgramAvailability
          handleNext={childHandleProgramNext}
          handleBack={childHandleProgramBack}
          availableProgramsAndServices={availableProgramsAndServices}
          programsAndServicesConfig={
            locationObj.programsAndServicesConfig
              ? locationObj.programsAndServicesConfig
              : {}
          }
        />
      );
    case 3:
      return (
        <Review
          locationObj={locationObj}
          handleBack={childHandleReviewBack}
          mode="addNewLocation"
          setAlertMessage={setAlertMessage}
        />
      );
    default:
      return "Unknown step";
  }
}

const availableProgramsAndServices = getAvailableProgramsAndService().map(
  obj => {
    let services = obj.services.map(service => {
      return {
        name: service.codeId + " - " + service.name,
        codeId: service.codeId
      };
    });

    return { name: obj.name, services };
  }
);

const Location = () => {
  const steps = getSteps();

  const [activeStep, setActiveStep] = useState(0);
  const [locationObj, setLocationObj] = useState({
    locationInfo: {
      locationId: "",
      locationName: "",
      bssrid: "",
      effectiveDate: moment(new Date()).format("YYYY-MM-DD"),
      building: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      longitude: "",
      latitude: "",
      description: "",
      locationType: "",
      partnerType: "",
      hostName: "",
      partnerName: "",
      partnerLocationId: "",
      airportCode: "",
      timezone: "US/CENTRAL",
      specialLocationFee: "",
      specialLocationAccessCode: "",
      appointments: false,
      sameDayAppointments: false,
      oneVisitEligible: false,
      walkins: false
    },
    hoursOfOperation: [],

    // contain the program and service that the user selects for configuration.
    programsAndServicesConfig: {}
  });

  const [alertMessage, setAlertMessage] = useState("");

  const handleNext = obj => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setLocationObj(obj);
  };

  const handleLocationInfoNext = locationInfo => {
    const tempData = locationObj;
    tempData.locationInfo = locationInfo;
    handleNext(tempData);
  };

  const handleHoursNext = hoursObj => {
    const tempData = locationObj;
    tempData.hoursOfOperation = hoursObj;
    handleNext(tempData);
  };

  const handleProgramNext = programsAndServicesConfig => {
    const tempData = locationObj;
    tempData.programsAndServicesConfig = programsAndServicesConfig;
    handleNext(tempData);
  };

  const handleHoursBack = hoursObj => {
    const tempData = locationObj;
    tempData.hoursOfOperation = hoursObj;
    handleBack(tempData);
  };

  const handleProgramBack = programsAndServicesConfig => {
    const tempData = locationObj;
    tempData.programsAndServicesConfig = programsAndServicesConfig;
    handleBack(tempData);
  };

  const handleReviewBack = () => {
    handleBack(locationObj);
  };

  const handleBack = obj => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setLocationObj(obj);
  };

  return (
    <Box className="box-margin">
      <SubHeader pageTitle="Add Location" tenantName="UE" />
      <Divider orientation="horizontal" />
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} id={`step-${index}`}>
            <StepLabel id={`${index}`}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <CustomAlert
        alertOpen={!!alertMessage}
        setAlertOpen={() => setAlertMessage(null)}
        alertMessage={alertMessage}
      />
      {getStepContent(
        activeStep,
        locationObj,
        handleLocationInfoNext,
        handleHoursNext,
        handleProgramNext,
        handleHoursBack,
        handleProgramBack,
        handleReviewBack,
        setAlertMessage
      )}
    </Box>
  );
};

export default Location;
