import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const Navbar = (props) => {
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => history.push("/login");
  const logout = async () => oktaAuth.signOut();

  return (
    <AppBar>
      <Toolbar>
        <Typography className={props.classes.flexGrow} variant="h1" id="logo">
          IDEMIA
        </Typography>
        {userInfo && authState.isAuthenticated && (
          <Typography variant="h6" id="profileName">
            {userInfo.name} {"\u00A0"}
            <i className="fas fa-user-cog"></i>
          </Typography>
        )}
        {authState.isAuthenticated && (
          <Button
            variant="contained"
            color="Primary"
            onClick={logout}
            id="logout"
          >
            Logout
          </Button>
        )}
        {!authState.isPending && !authState.isAuthenticated && (
          <Button variant="contained" color="primary" onClick={login}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
