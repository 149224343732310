import Search from "../components/Search";

export default function ServiceSearch(props) {
  return (
    <Search
      optionsValue={"name"}
      valueChanged={props.valueChanged}
      clearTextOnValueChanged={true}
      searchOptions={props.availableServices}
      customFilter={(option, filterValue) => {
        return (
          option.name.toLowerCase().indexOf(filterValue) >= 0 ||
          option.codeId.toLowerCase().indexOf(filterValue) >= 0
        );
      }}
      style={props.style}
      searchItemsHeader={"SERVICES OR CODE ID INCLUDED IN THIS PROGRAM"}
      searchNoItemsHeader={"NO SERVICES FOUND"}
      zIndex={49}
    />
  );
}
