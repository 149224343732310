import Box from "@material-ui/core/Box";
import ProgramHeaderRow from "./ProgramHeaderRow";
import ProgramRow from "./ProgramRow";

export default function MainTable(props) {
  /** How to get array of services object or that program */
  return (
    <Box style={props.style}>
      <ProgramHeaderRow />
      {props.availableProgramsAndServices
        .filter((program) => {
          if (props.filterToProgram) {
            return program.name === props.filterToProgram.name;
          } else if (props.filterToService) {
            return program.services.some((service) => {
              if (service.name === props.filterToService.name) {
                return program;
              }
            });
          }
          return true;
        })
        .map((program, index) => (
          <ProgramRow
            key={program.name}
            program={program}
            programConfig={
              props.programsCongfig !== undefined
                ? props.programsCongfig.find(
                    (modifiedProgram) => program.name === modifiedProgram.name
                  )
                : props.programsCongfig
            }
            /** There is something wrong here, how come the servicesConfig is not passed correctly */
            servicesConfig={
              props.servicesCongfig
                ? props.servicesCongfig.filter((modifiedService) => {
                    return modifiedService.programName === program.name;
                  })
                : props.servicesCongfig
            }
            index={index}
            showModal={props.showModal}
            programChanged={props.programChanged}
            serviceChanged={props.serviceChanged}
            serviceUpdated={props.serviceUpdated}
            filterToService={props.filterToService}
            collapsed={!(props.filterToService || props.filterToProgram)}
            programsAndServicesConfig={props.programsAndServicesConfig}
          />
        ))}
    </Box>
  );
}
