import { React, useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

let hasFocus = false;

export default function SpecialLocationAccessCodeInput(props) {
  function computeShowValue(value) {
    if (value === null || value === undefined || value === "") {
      return "";
    } else {
      if (value.startsWith("SP-")) {
        return value;
      } else {
        return "SP-" + value;
      }
    }
  }

  const computedShowValue = computeShowValue(props.value);

  const [showValue, setShowValue] = useState(computedShowValue);

  const valueChanged = (event) => {
    props.valueChange(event.target.value.replace("SP-", ""));
    setShowValue(event.target.value);
  };

  const autoCompleteHandler = (event) => {
    props.valueChange(event.target.textContent.replace("SP-", ""));
    setShowValue(event.target.textContent);
  };

  useEffect(() => {
    if (!hasFocus) {
      setShowValue(computeShowValue(props.value));
    }
  }, [props.value]);

  const focused = () => {
    setShowValue(props.value);
    hasFocus = true;
  };

  const focusLost = () => {
    setShowValue(computeShowValue(props.value));
    hasFocus = false;
  };

  return (
    <Autocomplete
      id="specialLocationAccessCodeAutoComplete"
      freeSolo
      autoSelect
      options={props.options}
      inputProps={{ maxLength: 50 }}
      onChange={autoCompleteHandler}
      value={showValue}
      label="Access Code"
      onFocus={focused}
      onBlur={focusLost}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          id="accessCode"
          name="specialLocationAccessCode"
          onChange={valueChanged}
          label="Access Code"
          error={props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
}
