const oktaClientId = process.env.REACT_APP_OKTA_CLIENT_ID;
const issuer = process.env.REACT_APP_OKTA_ISSUER;
const scopes = ["tenant_read", "openid", "profile", "email"];

export default function getOktaConfig() {
  if (
    process.env.REACT_APP_OKTA_CLIENT_ID &&
    process.env.REACT_APP_OKTA_ISSUER
  ) {
    return {
      clientId: oktaClientId,
      issuer: issuer,
      redirectUri: `${window.location.origin}/login/callback`,
      scopes: scopes,
      pkce: true,
    };
  } else {
    throw Error(
      "REACT_APP_OKTA_CLIENT_ID and/or REACT_APP_OKTA_ISSUER not set."
    );
  }
}
