import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  leftButton: {
    marginRight: "12px",
    width: "125px",
    flexShrink: 0,
    flexGrow: 0,
    "&.no-right-button": {
      marginRight: "0",
    },
  },
  rightButton: {
    width: "125px",
    flexShrink: 0,
    flexGrow: 0,
  },
}));

export default function NavButtons(props) {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.buttonContainer} ${props.buttonContainerClassName}`}
    >
      {props.hideLeftButton ? null : (
        <Button
          variant="contained"
          color={props.leftButtonColor ? props.leftButtonColor : "primary"}
          component={props.leftButtonComponent}
          to={props.leftButtonTo}
          className={`${classes.leftButton} ${props.leftButtonClassName} ${
            props.hideRightButton ? "no-right-button" : "right-button"
          }`}
          onClick={props.handleLeftButtonClick}
          id={props.leftButtonId}
          disabled={props.leftButtonDisabled}
        >
          {props.leftButtonText}
        </Button>
      )}
      {props.hideRightButton ? null : (
        <Button
          variant="contained"
          color={props.rightButtonColor ? props.rightButtonColor : "primary"}
          component={props.rightButtonComponent}
          to={props.rightButtonTo}
          className={`${classes.rightButton} ${props.rightButtonClassName}`}
          onClick={props.handleRightButtonClick}
          id={props.rightButtonId}
          disabled={props.rightButtonDisabled}
        >
          {props.rightButtonText}
        </Button>
      )}
    </Box>
  );
}
