export function getAvailableProgramsAndService() {
  return [
    {
      name: "Program #1",
      services: [
        {
          name: "Service P1a",
          codeId: "1-a",
        },
        {
          name: "Service P1b",
          codeId: "1-b",
        },
      ],
    },
    {
      name: "Program #2",
      services: [
        {
          name: "Service P2a Test",
          codeId: "2-a",
        },
        {
          name: "Service P2b Test",
          codeId: "2-b",
        },
        {
          name: "Service P2c Test",
          codeId: "2-c",
        },
        {
          name: "Service P2d abc",
          codeId: "2-d",
        },
        {
          name: "Service P2e ab",
          codeId: "2-e",
        },
        {
          name: "Service P2f abc",
          codeId: "2-f",
        },
        {
          name: "Service P2g",
          codeId: "2-g",
        },
      ],
    },
    {
      name: "Program #3",
      services: [
        {
          name: "Service P3a",
          codeId: "3-a",
        },
        {
          name: "Service P3b",
          codeId: "3-b",
        },
      ],
    },
    {
      name: "Program #4",
      services: [
        {
          name: "Service P4a",
          codeId: "4-a",
        },
        {
          name: "Service P4b",
          codeId: "4-b",
        },
      ],
    },
    {
      name: "Program #5",
      services: [
        {
          name: "Service P5a",
          codeId: "5-a",
        },
        {
          name: "Service P5b",
          codeId: "5-b",
        },
      ],
    },
    {
      name: "Program #6",
      services: [
        {
          name: "Service P6a",
          codeId: "6-a",
        },
        {
          name: "Service P6b",
          codeId: "6-b",
        },
      ],
    },
    {
      name: "Program #7",
      services: [
        {
          name: "Service P7a",
          codeId: "7-a",
        },
        {
          name: "Service P7b",
          codeId: "7-b",
        },
      ],
    },
    {
      name: "Program #8",
      services: [
        {
          name: "Service P8a",
          codeId: "8-a",
        },
        {
          name: "Service P8b",
          codeId: "8-b",
        },
      ],
    },
    {
      name: "Program #9",
      services: [
        {
          name: "Service P9a",
          codeId: "9-a",
        },
        {
          name: "Service P9b",
          codeId: "9-b",
        },
      ],
    },
    {
      name: "Program #10",
      services: [
        {
          name: "Service P10a",
          codeId: "10-a",
        },
        {
          name: "Service P10b",
          codeId: "10-b",
        },
      ],
    },
    {
      name: "Program #11",
      services: [
        {
          name: "Service P11",
          codeId: "11-a",
        },
      ],
    },
    {
      name: "Program #12",
      services: [
        {
          name: "Service P12",
          codeId: "12-a",
        },
      ],
    },
    {
      name: "Program #13",
      services: [
        {
          name: "Service P13",
          codeId: "13-a",
        },
      ],
    },
    {
      name: "Program #14",
      services: [
        {
          name: "Service P14",
          codeId: "14-a",
        },
      ],
    },
    {
      name: "Program #15",
      services: [
        {
          name: "Service P15",
          codeId: "15-a",
        },
      ],
    },
    {
      name: "Program #16",
      services: [
        {
          name: "Service P16",
          codeId: "16-a",
        },
      ],
    },
    {
      name: "Program #17",
      services: [
        {
          name: "Service P17",
          codeId: "17-a",
        },
      ],
    },
    {
      name: "Program #18",
      services: [
        {
          name: "Service P18",
          codeId: "18-a",
        },
      ],
    },
    {
      name: "Program #19",
      services: [
        {
          name: "Service P19",
          codeId: "19-a",
        },
      ],
    },
    {
      name: "Program #20",
      services: [
        {
          name: "Service P20",
          codeId: "20-a",
        },
      ],
    },
    {
      name: "Program #21",
      services: [
        {
          name: "Service P21",
          codeId: "21-a",
        },
      ],
    },
    {
      name: "Program #22",
      services: [
        {
          name: "Service P22",
          codeId: "22-a",
        },
      ],
    },
    {
      name: "Program #23",
      services: [
        {
          name: "Service P23",
          codeId: "23-a",
        },
      ],
    },
    {
      name: "Program #24",
      services: [
        {
          name: "Service P24",
          codeId: "24-a",
        },
      ],
    },
    {
      name: "Program #25",
      services: [
        {
          name: "Service P25",
          codeId: "25-a",
        },
      ],
    },
    {
      name: "Program #26",
      services: [
        {
          name: "Service P26",
          codeId: "26-a",
        },
      ],
    },
    {
      name: "Program #27",
      services: [
        {
          name: "Service P27",
          codeId: "27-a",
        },
      ],
    },
    {
      name: "Program #28",
      services: [
        {
          name: "Service P28",
          codeId: "28-a",
        },
      ],
    },
    {
      name: "Program #29",
      services: [
        {
          name: "Service P29",
          codeId: "29-a",
        },
      ],
    },
    {
      name: "Program #30",
      services: [
        {
          name: "Service P30",
          codeId: "30-a",
        },
      ],
    },
    {
      name: "Program #31",
      services: [
        {
          name: "Service P31",
          codeId: "31-a",
        },
      ],
    },
    {
      name: "Program #32",
      services: [
        {
          name: "Service P32",
          codeId: "32-a",
        },
      ],
    },
    {
      name: "Program #33",
      services: [
        {
          name: "Service P33",
          codeId: "33-a",
        },
      ],
    },
    {
      name: "Program #34",
      services: [
        {
          name: "Service P34",
          codeId: "34-a",
        },
      ],
    },
    {
      name: "Program #35",
      services: [
        {
          name: "Service P35",
          codeId: "35-test",
        },
      ],
    },
    {
      name: "Program #36",
      services: [
        {
          name: "Service P36",
          codeId: "36-test",
        },
      ],
    },
    {
      name: "Program #37",
      services: [
        {
          name: "Service P37",
          codeId: "37-test",
        },
      ],
    },
    {
      name: "Program #38",
      services: [
        {
          name: "Service P38",
          codeId: "38-test",
        },
      ],
    },
    {
      name: "Program #39",
      services: [
        {
          name: "Service P39",
          codeId: "39-test",
        },
      ],
    },
    {
      name: "Program #40",
      services: [
        {
          name: "Service P40",
          codeId: "40-test",
        },
      ],
    },
    {
      name: "Program #41",
      services: [
        {
          name: "Service P41",
          codeId: "41-test",
        },
      ],
    },
    {
      name: "Program #42",
      services: [
        {
          name: "Service P42",
          codeId: "42-test",
        },
      ],
    },
    {
      name: "Program #43",
      services: [
        {
          name: "Service P43",
          codeId: "43-test",
        },
      ],
    },
    {
      name: "Program #44",
      services: [
        {
          name: "Service P44",
          codeId: "44-test",
        },
      ],
    },
    {
      name: "Program #45",
      services: [
        {
          name: "Service P45",
          codeId: "45-test",
        },
      ],
    },
    {
      name: "Program #46",
      services: [
        {
          name: "Service P46",
          codeId: "46-test",
        },
      ],
    },
    {
      name: "Program #47",
      services: [
        {
          name: "Service P47",
          codeId: "47-test",
        },
      ],
    },
    {
      name: "Program #48",
      services: [
        {
          name: "Service P48",
          codeId: "48-test",
        },
      ],
    },
    {
      name: "Program #49",
      services: [
        {
          name: "Service P49",
          codeId: "49-test",
        },
      ],
    },
    {
      name: "Program #50",
      services: [
        {
          name: "Service P50",
          codeId: "50-test",
        },
      ],
    },
    {
      name: "Program #51",
      services: [
        {
          name: "Service P51",
          codeId: "51-test",
        },
      ],
    },
    {
      name: "Program #52",
      services: [
        {
          name: "Service P52",
          codeId: "52-test",
        },
      ],
    },
    {
      name: "Program #53",
      services: [
        {
          name: "Service P53",
          codeId: "53-test",
        },
      ],
    },
    {
      name: "Program #54",
      services: [
        {
          name: "Service P54",
          codeId: "54-test",
        },
      ],
    },
    {
      name: "Program #55",
      services: [
        {
          name: "Service P55",
          codeId: "55-test",
        },
      ],
    },
    {
      name: "Program #56",
      services: [
        {
          name: "Service P56",
          codeId: "56-test",
        },
      ],
    },
    {
      name: "Program #57",
      services: [
        {
          name: "Service P57",
          codeId: "57-test",
        },
      ],
    },
    {
      name: "Program #58",
      services: [
        {
          name: "Service P58",
          codeId: "58-test",
        },
      ],
    },
    {
      name: "Program #59",
      services: [
        {
          name: "Service P59",
          codeId: "59-test",
        },
      ],
    },
    {
      name: "Program #60",
      services: [
        {
          name: "Service P60",
          codeId: "60-test",
        },
      ],
    },
  ];
}
