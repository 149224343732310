import { stateArray } from "../stateArray";

export function getInfoFromLocationVersion(locationVersion) {
  return {
    locationId: locationVersion.locationId,
    locationName: locationVersion.locationName,
    bssrid: locationVersion.bssrid,
    effectiveDate: locationVersion.effectiveDate
      ? locationVersion.effectiveDate.slice(0, 10)
      : null,
    building: locationVersion.building,
    addressLine1: locationVersion.addressLine1,
    addressLine2: locationVersion.addressLine2,
    city: locationVersion.city,
    state: stateArray.find(
      (val) => !!val.state && val.state.startsWith(locationVersion.state)
    ).state,
    postalCode: locationVersion.postalCode,
    longitude: locationVersion.longitude,
    latitude: locationVersion.latitude,
    description: locationVersion.description,
    locationType: locationVersion.locationType,
    partnerType: locationVersion.partnerType,
    hostName: locationVersion.hostName,
    partnerName: locationVersion.partnerName,
    partnerLocationId: locationVersion.partnerExternalId,
    airportCode: locationVersion.airportCode,
    timezone: locationVersion.timezone,
    specialLocationFee: locationVersion.specialLocationFee,
    specialLocationAccessCode: locationVersion.specialLocationAccessCode
      ? locationVersion.specialLocationAccessCode
      : "",
    appointments: locationVersion.appointments,
    sameDayAppointments: locationVersion.sameDayAppointments,
    oneVisitEligible: locationVersion.oneVisitEligible,
    walkins: locationVersion.walkins,
    salesTaxRate: locationVersion.salesTaxRate,
  };
}
