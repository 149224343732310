import Box from "@material-ui/core/Box";
import HoursOfOperationDataTable from "./HoursOfOperationDataTable";
import { React, useEffect, useState } from "react";
import HoursOfOperationNewRowControl from "./HoursOfOperationNewRowControl";
import {
  hasDaysOverlap,
  hasTimeOverlap,
  isEndTimeAfterStartTime,
  isOneOrMoreDaysChecked,
} from "./Util/validations";

export default function HoursOfOperation(props) {
  const [errorMessages, setErrorMessages] = useState([]);

  const controlIsValid = () => {
    let isValid = props.hoursOfOperationData.map(() => true);
    let validity;

    if (props.hoursOfOperationData.length === 0) {
      validity = true;
    } else {
      for (let i = 0; i < props.hoursOfOperationData.length; ++i) {
        if (isValid[i]) {
          const datum = props.hoursOfOperationData[i];
          let rowIsValid =
            isEndTimeAfterStartTime(datum.startTime, datum.endTime) &&
            isOneOrMoreDaysChecked(datum);

          if (rowIsValid) {
            for (let j = 0; j < props.hoursOfOperationData.length; ++j) {
              if (i !== j) {
                const checkDatum = props.hoursOfOperationData[j];
                const daysOverlap = hasDaysOverlap(datum, checkDatum);

                if (daysOverlap) {
                  rowIsValid = !hasTimeOverlap(
                    datum.startTime,
                    datum.endTime,
                    checkDatum.startTime,
                    checkDatum.endTime
                  );
                }

                if (!rowIsValid) {
                  setErrorMessage(
                    "Has an overlapping timeslot with row: " + (j + 1),
                    i
                  );
                  setErrorMessage(
                    "Has an overlapping timeslot with row: " + (i + 1),
                    j
                  );
                  isValid[j] = false;
                  break;
                }
              }
            }
          }

          if (rowIsValid) {
            setErrorMessage("", i);
          } else {
            isValid[i] = false;
          }
        }
      }

      validity = !isValid.some((x) => !x);
    }

    props.isContentValidChange(validity);
    return validity;
  };

  useEffect(() => {
    controlIsValid();
  }, []);

  const newHoursRowData = (hoursRowData) => {
    let tempData = props.hoursOfOperationData;
    tempData.push(hoursRowData);
    props.onHoursOfOperationDataChanged(tempData);

    controlIsValid();

    let tempMessages = errorMessages;
    tempMessages.push("");
    setErrorMessages([...tempMessages]);
  };

  const hoursOfOperationDataChanged = (data, index) => {
    let tempData = props.hoursOfOperationData;
    tempData[index] = data;
    props.onHoursOfOperationDataChanged(tempData);

    controlIsValid();
  };

  const setErrorMessage = (message, rowIndex) => {
    let tempMessages = errorMessages;
    tempMessages[rowIndex] = message;
    setErrorMessages([...tempMessages]);
  };

  const deleteRow = (rowIndex) => {
    let tempData = props.hoursOfOperationData;
    tempData.splice(rowIndex, 1);
    props.onHoursOfOperationDataChanged(tempData);

    controlIsValid();

    let tempMessages = errorMessages;
    tempMessages.splice(rowIndex, 1);
    setErrorMessages([...tempMessages]);
  };

  const orderUp = (rowIndex) => {
    if (rowIndex > 0 && rowIndex < props.hoursOfOperationData.length) {
      let tempData = props.hoursOfOperationData;
      let row = tempData[rowIndex];
      tempData[rowIndex] = tempData[rowIndex - 1];
      tempData[rowIndex - 1] = row;
      props.onHoursOfOperationDataChanged(tempData);

      controlIsValid();
    }
  };

  const orderDown = (rowIndex) => {
    if (rowIndex >= 0 && rowIndex < props.hoursOfOperationData.length - 1) {
      const tempData = props.hoursOfOperationData;
      const row = tempData[rowIndex];
      tempData[rowIndex] = tempData[rowIndex + 1];
      tempData[rowIndex + 1] = row;
      props.onHoursOfOperationDataChanged(tempData);

      controlIsValid();
    }
  };

  return (
    <Box mx={1}>
      <HoursOfOperationNewRowControl
        sendNewHoursRowDataToParent={newHoursRowData}
      />
      <HoursOfOperationDataTable
        hoursOfOperationData={props.hoursOfOperationData}
        dataChanged={hoursOfOperationDataChanged}
        errorMessages={errorMessages}
        deleteRow={deleteRow}
        orderUp={orderUp}
        orderDown={orderDown}
      />
    </Box>
  );
}
