import CustomDialog from "../components/CustomDialog";
import { Typography } from "@material-ui/core";
import { React, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useOktaAuth } from "@okta/okta-react";
import { responseHandler } from "../utilities/HttpResponseHandler";
import { messages } from "../Constants/Message";
import { getDomain } from "../Domain";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CancelClosureModal(props) {
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  const [cancelClosureStep, setCancelClosureStep] = useState(
    "confirmCancelClosure"
  );

  const confirmedCancelClosure = () => {
    const accessToken = oktaAuth.getAccessToken();

    setCancelClosureStep("saving");
    fetch(
      `${getDomain()}tenants/UE/location/${props.location.locationId}/close`,
      {
        method: "delete",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        return responseHandler(response);
      })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        props.setAlertMessage(messages.serverError);
        props.closeHandler();
      });
  };

  const closeDialog = () => {
    props.closeHandler();
  };

  switch (cancelClosureStep) {
    case "confirmCancelClosure":
      return (
        <CustomDialog
          open={props.open}
          showCloseX={false}
          closeAction={null}
          header={"Cancel Location Closure"}
          content={
            <Typography variant="h3">
              Are you sure you want to cancel the location closure?
            </Typography>
          }
          leftButtonText={"Cancel Closure"}
          onLeftButtonClick={confirmedCancelClosure}
          rightButtonText={"Close"}
          onRightButtonClick={closeDialog}
          id="closureModal"
        />
      );
    case "saving":
      return (
        <CustomDialog
          open={props.open}
          header={"Saving....."}
          showCloseX={false}
          content={
            <CircularProgress size={36} className={classes.buttonProgress} />
          }
        />
      );
  }
}
